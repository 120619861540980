let mode = {
    "prod": "prod",
    "dev": "dev"
}

let CurrentMode = mode.dev;

export const RealtimeDatabasePaths = {
    "userRoles": `${CurrentMode}/userRoles`,
    "chat": `${CurrentMode}/chat`,
    "AvatarDetails": `${CurrentMode}/avatarDetails`,
    "Analytics": `${CurrentMode}/analytics`,
}