import { useEffect } from "react";
import styles from "./index.module.scss";
const RotateMessage = () => {
  useEffect(() => {
    let allInputs = document.querySelectorAll("input");
    let rotateID = document.querySelector(`#${styles.rotate}`);

    allInputs.forEach((inp) => {
      inp.addEventListener("focus", () => {
        rotateID.style.display = "none";
      });
    });
    return () => {
      allInputs.forEach((inp) => {
        inp.removeEventListener("focus", () => {
          rotateID.style.display = "none";
        });
      });
    };
  }, []);

  return (
    <div id={styles.rotate}>
      <div className={styles.phone}></div>
      <div className={styles.message}>Please rotate your device!</div>
    </div>
  );
};

export default RotateMessage;
