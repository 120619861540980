import { useContext } from "react";
import { useEffect, useState, useRef } from "react";
import { isMobile } from "react-device-detect";
import { defaultNotificationIcon } from "../../constant/AssetsConstant";
import { NotificationContext } from "../../contexts/NotificationContextProvider";
import CloseIcon from "../SVGIcons";
import styles from "./index.module.scss";

const NotificationPopUp = () => {
  const { notificationState } = useContext(NotificationContext);
  const [showNotification, setShowNotification] = useState({
    show: false,
    msg: "",
  });
  const notificationRef = useRef(null);

  useEffect(() => {
    if (notificationState.flashNotification) {
      setShowNotification({
        show: true,
        msg: notificationState.flashNotification.message,
      });
      console.log(notificationState.flashNotification);
    } else {
      setShowNotification({
        show: false,
        msg: "",
      });
    }
  }, [notificationState.flashNotification]);

  useEffect(() => {
    if (notificationRef && notificationRef.current) {
      if (showNotification.show) {
        notificationRef.current.style.marginBottom = "0%";
        notificationRef.current.style.opacity = 1;
      } else {
        if (isMobile) {
          notificationRef.current.style.marginBottom = "-40%";
        } else {
          notificationRef.current.style.marginBottom = "-20%";
        }
        notificationRef.current.style.opacity = 0;
      }
    }
  }, [showNotification]);

  const handleCLose = () => {
    setShowNotification({ show: false, msg: "" });
  };

  return (
    <div ref={notificationRef} className={`${styles.container}`}>
      <img src={"/logo192.png"} alt="" />
      <p>{showNotification.msg}</p>
      <div onClick={handleCLose} className={styles.closeBtn}>
        <CloseIcon size="16" />
      </div>
    </div>
  );
};

export default NotificationPopUp;
