import { useContext, useState } from "react";
import { Map, NotificationPopUp } from "../../Components";
import Sidebar from "../../Components/Sidebar/Sidebar";
import CloseIcon, {
  ChatIcon,
  EmoteIcon,
  LocationIcon,
  NotificationIcon,
  VolumeIcon,
} from "../../Components/SVGIcons";
import { ChatContext } from "../../contexts/ChatContextProvider";
import { MenuStates, UIContext } from "../../contexts/UIContextProvider";
import ReactNipple from "react-nipple";
import DebugView from "react-nipple/lib/DebugView";

// optional: include the stylesheet somewhere in your app
import "react-nipple/lib/styles.css";
import styles from "./index.module.scss";
import SocketNotificationContainer from "../../Components/NotificationContainer/SocketNotificationContainer";
import { NotificationContext } from "../../contexts/NotificationContextProvider";
import { Endpoints } from "../../constant/EndPoints";
import ChatBot from "../../Components/ChatBot";
import { AvatarContext } from "../../contexts/AvatarContextProvider";
import { EMOTES_IMAGES } from "../../constant/AssetsConstant";

const menuItems = [
  { enable: true, id: "emote", type: "" },
  { enable: true, id: "volume", type: "" },
  { enable: true, id: "chat", type: "" },
];

const emoteItems = [
  { enable: true, id: "thumb", img: EMOTES_IMAGES.thumb, emoteIndex: 1 },
  { enable: true, id: "heart", img: EMOTES_IMAGES.heart, emoteIndex: 2 },
  { enable: true, id: "rock", img: EMOTES_IMAGES.rock, emoteIndex: 3 },
  { enable: true, id: "smile", img: EMOTES_IMAGES.smile, emoteIndex: 4 },
];

const Home = ({ switchComponent }) => {
  const { notificationState } = useContext(NotificationContext);
  const {
    state,

    bgAudioRef,
    handleBGVolumeBtnToggle,
  } = useContext(AvatarContext);

  const [joystickData, setJoystickData] = useState({});
  const [isEmoteOpen, setIsEmoteOpen] = useState(false);

  const {
    activeMenu,
    toggleSideMenu,
    setActiveMenu,
    toggleMenuOpen,
    isMenuOpen,
  } = useContext(UIContext);
  const { activeRoom } = useContext(ChatContext);

  const openMenu = (st, hidePrevent) => {
    if (activeMenu !== st) {
      setActiveMenu(st);
    } else if (!hidePrevent) {
      toggleMenuOpen(false);
    }
  };

  const handleJoyStick = (evt, data) => {
    console.log(evt);
    setJoystickData(data);
  };

  const handleOnCreate = (instance) => {
    window.parent.reactToPc.setNippleManagerAccess(instance);
  };

  const handleVolumeBtn = () => {
    handleBGVolumeBtnToggle();
  };

  const toggleEmote = () => {
    setIsEmoteOpen((prev) => !prev);
  };

  const handleEmoteClick = (emote) => {
    if (window.parent.canvasTest) {
      if (window.parent.canvasTest.emotePlayer) {
        window.parent.canvasTest.emotePlayer(emote.emoteIndex);
      }
    }
    console.log(emote);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.topBtnContainer}>
          <div className={styles.left}></div>
          <div className={styles.right}>
            <div
              onClick={() => openMenu(MenuStates.notification)}
              className={`${styles.imgContainer} ${styles.shakeAnimation}`}
              style={{
                backgroundColor:
                  activeMenu === MenuStates.notification
                    ? "#fff"
                    : "rgba(30, 30, 30, 0.25)",
                borderColor:
                  activeMenu === MenuStates.notification
                    ? "rgba(0, 0, 0, 0.6)"
                    : "rgba(255, 255, 255, 0.6)",
              }}
            >
              <NotificationIcon
                color={activeMenu === MenuStates.notification ? "#000" : "#fff"}
              />
            </div>
            <div
              onClick={() => openMenu(MenuStates.map)}
              className={`${styles.imgContainer} ${styles.shakeAnimation}`}
              style={{
                backgroundColor:
                  activeMenu === MenuStates.map
                    ? "#fff"
                    : "rgba(30, 30, 30, 0.25)",
                borderColor:
                  activeMenu === MenuStates.map
                    ? "rgba(0, 0, 0, 0.6)"
                    : "rgba(255, 255, 255, 0.6)",
              }}
            >
              <LocationIcon
                color={activeMenu === MenuStates.map ? "#000" : "#fff"}
              />
            </div>
          </div>
        </div>
        <div className={styles.bottomBtnContainer}>
          <div className={`${styles.joystick}`} id="joystickSlot">
            {/* <ReactNipple
            options={{
              mode: "static",
              position: { top: "50%", left: "50%" },
            }}
            style={{
              // outline: "1px dashed red",
              color: "blue",
              width: '150px',
              height: '150px',
              position: "relative",
            }}
            onCreated={handleOnCreate}
            onStart={handleJoyStick}
            onEnd={handleJoyStick}
            onMove={handleJoyStick}
            onDir={handleJoyStick}
            onPlain={handleJoyStick}
            onShown={handleJoyStick}
            onHidden={handleJoyStick}
            onPressure={handleJoyStick}
          /> */}
            {/* <DebugView data={joystickData} /> */}
          </div>
          <div className="btns">
            {menuItems.map(
              ({ enable, id, type, img }) =>
                enable && (
                  <div
                    key={id}
                    className={`${styles.imgContainer} ${
                      id !== "emote" && styles.shakeAnimation
                    }`}
                    onClick={
                      id === "chat"
                        ? toggleSideMenu
                        : id === "volume"
                        ? handleVolumeBtn
                        : id === "emote"
                        ? toggleEmote
                        : null
                    }
                    style={{
                      backgroundColor:
                        isMenuOpen && id === "chat"
                          ? "#fff"
                          : id === "volume" && state.isAudioPlaying
                          ? "#fff"
                          : id === "emote" && isEmoteOpen
                          ? "#fff"
                          : "rgba(30, 30, 30, 0.25)",
                      borderColor:
                        isMenuOpen && id === "chat"
                          ? "rgba(0, 0, 0, 0.6)"
                          : id === "volume" && state.isAudioPlaying
                          ? "rgba(0, 0, 0, 0.6)"
                          : id === "emote" && isEmoteOpen
                          ? "rgba(0, 0, 0, 0.6)"
                          : "rgba(255, 255, 255, 0.6)",
                    }}
                  >
                    {id === "emote" ? (
                      <div className={styles.emoteContainer}>
                        <div
                          className={styles.emoteList}
                          style={{ display: isEmoteOpen ? "flex" : "none" }}
                        >
                          {emoteItems.map(
                            (emote) =>
                              emote.enable && (
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleEmoteClick(emote);
                                  }}
                                  key={emote.id}
                                  className={styles.item}
                                >
                                  <img src={emote.img} alt="" />
                                </div>
                              )
                          )}
                        </div>
                        <div
                          className={styles.emoteClose}
                          style={{ backgroundColor: isEmoteOpen ? "#fff" : "" }}
                        >
                          {isEmoteOpen ? (
                            <CloseIcon color={"#000"} />
                          ) : (
                            <RenderIcon id={id} color={"#fff"} />
                          )}
                        </div>
                      </div>
                    ) : (
                      <RenderIcon
                        id={id}
                        color={
                          isMenuOpen && id === "chat"
                            ? "#000"
                            : id === "volume" && state.isAudioPlaying
                            ? "#000"
                            : "#fff"
                        }
                      />
                    )}
                  </div>
                )
            )}
          </div>
        </div>
        {activeMenu && activeMenu !== MenuStates.map && (
          <Sidebar desktop={false}>
            <div className={styles.sidebarHeader}>
              <div>LIVE CHAT</div>
              <div className={`${styles.closeIcon}`} onClick={toggleSideMenu}>
                <CloseIcon />
              </div>
            </div>
            {activeRoom ? null : (
              <header className={`${styles.headerContainer}`}>
                <div
                  className={styles.headerItem}
                  style={{
                    borderBottom:
                      activeMenu === MenuStates.participants
                        ? "3px solid #FF4651"
                        : "3px solid transparent",
                    opacity: activeMenu === MenuStates.participants ? 1 : 0.75,
                  }}
                  onClick={() => openMenu(MenuStates.participants)}
                >
                  <p>Participants</p>
                </div>
                <div
                  className={styles.headerItem}
                  style={{
                    borderBottom:
                      activeMenu === MenuStates.publicChat
                        ? "3px solid #FF4651"
                        : "3px solid transparent",
                    opacity: activeMenu === MenuStates.publicChat ? 1 : 0.75,
                  }}
                  onClick={() => openMenu(MenuStates.publicChat)}
                >
                  <p>Public Chat</p>
                </div>
              </header>
            )}
          </Sidebar>
        )}
        {activeMenu === MenuStates.map && <Map />}
        {activeMenu === MenuStates.notification && (
          <>
            <SocketNotificationContainer
              id={"platform-notification"}
              isAdmin={
                false
                  ? notificationState.isPollAdmin
                  : notificationState.platform_isNotificationAdmin
              }
              onPlatform={true}
            />
          </>
          // <NotificationContainer
          //   id={notificationState.currentRoomId}
          //   isAdmin={notificationState.isPollAdmin}
          // />
        )}
      </div>
      <ChatBot />
      <NotificationPopUp />
    </>
  );
};
const RenderIcon = ({ id, color }) => {
  switch (id) {
    case "chat":
      return <ChatIcon color={color} />;
    case "emote":
      return <EmoteIcon color={color} />;
    case "volume":
      return <VolumeIcon color={color} />;

    default:
      return;
  }
};
export default Home;
