import React, { useContext } from "react";
import { ChatContext, ROOM_TYPE } from "../../contexts/ChatContextProvider";

import Participants from "../Participants/Participants";
import PrivateChatRoom from "../PrivateChatRoom/PrivateChatRoom";
import PrivateGroupChat from "../PrivateGroupChat/PrivateGroupChat";

export default function PrivateChat(props) {
  const { activeRoom } = useContext(ChatContext);
  const { profanityCheck } = props;
  return (
    <>
      {activeRoom ? (
        <>
          {activeRoom.type === ROOM_TYPE["one-On-one"] && (
            <PrivateChatRoom profanityCheck={profanityCheck} />
          )}
          {activeRoom.type === ROOM_TYPE.privateGroup && (
            <PrivateGroupChat profanityCheck={profanityCheck} />
          )}
        </>
      ) : (
        <Participants />
      )}
    </>
  );
}
