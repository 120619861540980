
import React, { useContext, useState } from 'react'
import ReactPlayer from 'react-player'
import { MediaModalContext } from '../../contexts/MediaModalContextProvider'
import './MediaModal.css'



export const ContentType = {
    Video: 'video',
    Pdf: 'pdf',
    Image: 'image',
    Iframe: 'iframe',
    FullIframe: 'FullIframe',
    Component: 'component',
    FullComponent: 'full_component',
}


export default function MediaModal() {
    const [iframeLoaded, setIframeLoaded] = useState(false);
    const { closeMediaModal, mediaModalStatus, modalDetails } = useContext(MediaModalContext)
    return (
        <>
            {
                mediaModalStatus &&
                <>
                    {
                        modalDetails.type === ContentType.FullComponent ?
                            <modalDetails.component data={modalDetails.data} handleClose={closeMediaModal} />
                            :
                            <div className="mediaModal">
                                <span className={`font mediaModalCloseBtn ${modalDetails.type === ContentType.Component ? 'componentFrameClose' : ''}`} ><b style={{ fontWeight: "bold" }}>X</b> Close</span>
                                {/* {
                            modalDetails.type === ContentType.Iframe &&
                            <BlueCircularCloseButton
                                className={classes.closeBUtton}
                                onClick={(e) => {
                                    if (e) { e.preventDefault() }
                                    closeMediaModal()
                                }}
                            />
                            // <div className="mediaModal_container_closeBtn" onClick={(e) => {
                            //     if (e) { e.preventDefault() }
                            //     closeMediaModal()
                            // }}>
                            // </div>
                        } */}
                                <div className="mediaModalCloser" onClick={(e) => {
                                    if (e) { e.preventDefault() }
                                    // if (modalDetails.type !== ContentType.Iframe)
                                    setIframeLoaded(false);
                                    closeMediaModal()
                                }}></div>
                                <div className={`mediaModal_container ${modalDetails.type === ContentType.Video ? 'mediaModal_container_video' : ''} ${modalDetails.type === ContentType.Pdf ? 'mediaModal_container_pdf' : ''} ${modalDetails.type === ContentType.Image ? 'mediaModal_container_image' : ''} ${modalDetails.type === ContentType.Iframe ? 'mediaModal_container_iframe' : ''} ${modalDetails.type === ContentType.Pdf ? 'pdf-full' : ''} ${modalDetails.type === ContentType.Component ? 'componentFrame' : ''} 
                        ${modalDetails.type === ContentType.FullComponent ? 'componentFrame componentFrame_Full' : ''} ${modalDetails.type === ContentType.FullIframe ? 'mediaModal_container_fullIframe' : ''}`}
                                // style={modalDetails.type === ContentType.Component ? { width: 'auto' } : {}}
                                >
                                    {
                                        modalDetails.type === ContentType.Image &&
                                        <img src={modalDetails.link} alt="imageLink" />
                                    }
                                    {
                                        modalDetails.type === ContentType.Video &&
                                        <ReactPlayer
                                            playing={true}
                                            url={modalDetails.link}
                                            playsinline={true}
                                            volume={0.85}
                                            controls={true}
                                            width='100%'
                                            height='100%'
                                            style={{
                                                background: 'black',
                                                borderRadius: '0.5rem'
                                            }}
                                        />
                                    }
                                    {
                                        modalDetails.type === ContentType.FullIframe &&
                                        <iframe className="iframeViewer iframe_full" src={modalDetails.link} title="Iframe" allow="camera *;microphone *" />
                                    }
                                    {
                                        modalDetails.type === ContentType.Iframe &&
                                        <iframe onLoad={(e)=>setIframeLoaded(true)} style={{backgroundImage: iframeLoaded? "": `url(${"./assets/images/loading2.gif"}`, backgroundRepeat:"no-repeat", backgroundPosition:"center",backgroundSize:"8rem"}} className="iframeViewer" src={modalDetails.link} title="Iframe" allow="camera *;microphone *" />
                                    }
                                    {
                                        modalDetails.type === ContentType.Pdf &&
                                        <>
                                            <iframe className="iframeViewer" src={modalDetails.link} title={modalDetails.name} allow="camera *;microphone *" />
                                            {/* <PDFViewer link={modalDetails.link} name={modalDetails.name ?? 'Resource'} /> */}
                                        </>
                                    }
                                    {
                                        modalDetails.type === ContentType.Component &&
                                        <modalDetails.component data={modalDetails.data} />
                                    }

                                    {
                                        modalDetails.type !== ContentType.Component && modalDetails.type !== ContentType.FullComponent &&
                                        <div className="mediaModal_container_loader">
                                            <div className="lds-dual-ring"></div>
                                        </div>
                                    }
                                </div>
                            </div>
                    }
                </>
            }
        </>
    )
}
