import cricInfoImg from "../assets/images/cricinfo-verse.png";
import loaderImg from "../assets/images/Loader.gif";
import walkThroughDesktopBG from "../assets/images/walkthroughDesktop.jpg";
import walkThroughMobileBG from "../assets/images/walkthroughMobile.jpg";
import hairIcon from "../assets/images/avatar/type/hair.png";
import leftArrowIcon from "../assets/images/icons/leftArrowIcon.png";
import bottomBorderImg from "../assets/images/icons/bottomBorder.png";
import tShirtIcon from "../assets/images/avatar/type/tshirt.png";
import faceIcon from "../assets/images/avatar/type/face.png";
import specsIcon from "../assets/images/avatar/type/specs.png";
import canvasLoading from "../assets/images/canvasLoading.jpg";
import lobbyMap from "../assets/images/map/lobby.png";
import gameRoomMap from "../assets/images/map/gameRoom.png";
import statsRoomMap from "../assets/images/map/statsRoom.png";
import brandRoomMap from "../assets/images/map/brandRoom.png";
import liveShowMap from "../assets/images/map/liveShow.png";
import defaultNotificationIcon from "../assets/images/defaultNotificationIcon.png";
import lobbyWithUserMap from "../assets/images/map/withUser/lobby_1.png";
import gameRoomWithUserMap from "../assets/images/map/withUser/gameRoom_1.png";
import statsRoomWithUserMap from "../assets/images/map/withUser/statsRoom_1.png";
import brandRoomWithUserMap from "../assets/images/map/withUser/brandRoom_1.png";
import liveShowWithUserMap from "../assets/images/map/withUser/liveShow_1.png";
import clipIconImage from "../assets/images/clipIcon.png";

import Female_Cool_Voice from "../assets/music/chatAudioClip/female/cool.mp3";
import Female_Follow_Me_Voice from "../assets/music/chatAudioClip/female/follow_me.mp3";
import Female_Hi_There_Voice from "../assets/music/chatAudioClip/female/hi_there.mp3";
import Female_Lets_Chat_Voice from "../assets/music/chatAudioClip/female/lets_chat.mp3";
import Female_Lets_Play_Voice from "../assets/music/chatAudioClip/female/lets_play.mp3";
import Female_Whats_Up_Voice from "../assets/music/chatAudioClip/female/whats_up.mp3";

import Male_Cool_Voice from "../assets/music/chatAudioClip/male/cool.mp3";
import Male_Follow_Me_Voice from "../assets/music/chatAudioClip/male/follow_me.mp3";
import Male_Hi_There_Voice from "../assets/music/chatAudioClip/male/hi_there.mp3";
import Male_Lets_Chat_Voice from "../assets/music/chatAudioClip/male/lets_chat.mp3";
import Male_Lets_Play_Voice from "../assets/music/chatAudioClip/male/lets_play.mp3";
import Male_Whats_Up_Voice from "../assets/music/chatAudioClip/male/whats_up.mp3";

import emoteThumbImg from "../assets/images/emote/thumb.png";
import heartThumbImg from "../assets/images/emote/heart.png";
import rockThumbImg from "../assets/images/emote/rock.png";
import smileThumbImg from "../assets/images/emote/smile.png";

import touchLottie from "../assets/lotties/experienceTut/Drag.json";
import joystickLottie from "../assets/lotties/experienceTut/joystick.json";
import keyboardLottie from "../assets/lotties/experienceTut/keyboard.json";

import bgMusic from "../assets/music/ambientMusic.mp3";

import tShirt1 from "../assets/images/avatar/shirt6.png";
import tShirt2 from "../assets/images/avatar/shirt2.png";
import tShirt3 from "../assets/images/avatar/shirt4.png";
import tShirt4 from "../assets/images/avatar/shirt3.png";
import tShirt5 from "../assets/images/avatar/shirt1.png";
import tShirt6 from "../assets/images/avatar/shirt5.png";

import acc1 from "../assets/images/avatar/acc1.png";
import acc2 from "../assets/images/avatar/acc2.png";
import acc3 from "../assets/images/avatar/acc3.png";

import mHair1 from "../assets/images/avatar/mHair1.png";
import mHair2 from "../assets/images/avatar/mHair2.png";
import mHair3 from "../assets/images/avatar/mHair3.png";

import fHair1 from "../assets/images/avatar/fHair1.png";
import fHair2 from "../assets/images/avatar/fHair2.png";
import fHair3 from "../assets/images/avatar/fHair3.png";

import vehicle1 from "../assets/images/vehicle/1.JPG";
import vehicle2 from "../assets/images/vehicle/2.jpg";
import vehicle3 from "../assets/images/vehicle/3.jpg";
import vehicle4 from "../assets/images/vehicle/4.jpg";
import vehicle5 from "../assets/images/vehicle/5.jpg";
import vehicle6 from "../assets/images/vehicle/6.jpg";

export const vehicleImages = [
  vehicle1,
  vehicle2,
  vehicle3,
  vehicle4,
  vehicle5,
  vehicle6,
];

const EMOTES_IMAGES = {
  thumb: emoteThumbImg,
  heart: heartThumbImg,
  rock: rockThumbImg,
  smile: smileThumbImg,
};

const AVATAR_ASSET_CATEGORY = {
  hair: hairIcon,
  face: faceIcon,
  specs: specsIcon,
  tShirt: tShirtIcon,
};

const AVATAR_MALE_ACCESSORIES = {
  mhair: {
    hair1: mHair1,
    hair2: mHair3,
    hair3: mHair2,
  },
  fhair: {
    hair1: fHair3,
    hair2: fHair1,
    hair3: fHair2,
  },
  specs: {
    specs1: acc2,
    specs2: acc1,
    specs3: acc3,
  },
  tShirt: {
    tShirt1: tShirt1,
    tShirt2: tShirt2,
    tShirt3: tShirt3,
    tShirt4: tShirt4,
    tShirt5: tShirt5,
    tShirt6: tShirt6,
  },
};

const LOTTIE_TUT_DATA = {
  touch: touchLottie,
  joystick: joystickLottie,
  keyboard: keyboardLottie,
};

const MAP_DATA = {
  lobby: lobbyMap,
  gameRoom: gameRoomMap,
  statsRoom: statsRoomMap,
  brandRoom: brandRoomMap,
  liveShow: liveShowMap,

  lobbyWithUser: lobbyWithUserMap,
  gameRoomWithUser: gameRoomWithUserMap,
  statsRoomWithUser: statsRoomWithUserMap,
  brandRoomWithUser: brandRoomWithUserMap,
  liveShowWithUser: liveShowWithUserMap,
};

const AUDIO_CLIP_DATA = {
  male: {
    cool: Male_Cool_Voice,
    FOLLOW_ME: Male_Follow_Me_Voice,
    HI_THERE: Male_Hi_There_Voice,
    LETS_PLAY: Male_Lets_Play_Voice,
    LETS_CHAT: Male_Lets_Chat_Voice,
    WHATS_UP: Male_Whats_Up_Voice,
  },
  female: {
    cool: Female_Cool_Voice,
    FOLLOW_ME: Female_Follow_Me_Voice,
    HI_THERE: Female_Hi_There_Voice,
    LETS_PLAY: Female_Lets_Play_Voice,
    LETS_CHAT: Female_Lets_Chat_Voice,
    WHATS_UP: Female_Whats_Up_Voice,
  },
};

export {
  cricInfoImg,
  loaderImg,
  AVATAR_ASSET_CATEGORY,
  AVATAR_MALE_ACCESSORIES,
  canvasLoading,
  leftArrowIcon,
  LOTTIE_TUT_DATA,
  bottomBorderImg,
  MAP_DATA,
  defaultNotificationIcon,
  bgMusic,
  walkThroughDesktopBG,
  walkThroughMobileBG,
  EMOTES_IMAGES,
  AUDIO_CLIP_DATA,
  clipIconImage,
};
