import { Endpoints } from "./EndPoints";

export const defaultChatBotData = {
  intro: {
    id: "intro",
    name: "Raunak Kapoor",
    greetings: "Hi I am Raunak Kapoor and welcome to CricinfoVerse,",
    currentTime: new Date().getTime(),
    type: "question",
    btnDisable: false,
    questionData: {
      title: "Do you like me to tell you some interesting T20 Wc Trivia?",
      link: "https://vimeo.com/762853772/be68312400 ",// "https://storage.googleapis.com/expn-metaverse.appspot.com/liveChat/1.mp4",//
      buttons: [
        {
          id: "yes",
          text: "Yes",
          title: "",
          type: "video",
          link: Endpoints.chatBotIntroVideo,
        },
        {
          id: "no",
          text: "No",
          title:
            "It was nice meeting you, Do come again for some interesting cricket trivia and don't forget to follow all the live action on ESPNcricinfo",
          type: "end",
        },
      ],
    },
  },
  question1: {
    id: "question1",
    name: "Raunak Kapoor",
    greetings: "",
    currentTime: new Date().getTime(),
    type: "question",
    btnDisable: false,
    questionData: {
      title: "Did you know that no host nation has ever won the T20 WC?",
      link: "https://vimeo.com/762853935/6cd690193b ",// "https://storage.googleapis.com/expn-metaverse.appspot.com/liveChat/2.mp4",//

      buttons: [
        {
          id: "yes",
          text: "Yes",
          title: "",
          type: "video",
          link: Endpoints.chatBotQuestion1Video,
        },
        {
          id: "no",
          text: "No",
          title:
            "It was nice meeting you, Do come again for some interesting cricket trivia and don't forget to follow all the live action on ESPNcricinfo",
          type: "end",
        },
      ],
    },
  },
  question2: {
    id: "question2",
    name: "Raunak Kapoor",
    greetings: "",
    currentTime: new Date().getTime(),
    type: "question",
    btnDisable: false,
    questionData: {
      title:
        "Did you know that West Indies is the only team to have won T20 WC twice?",
      link: "https://vimeo.com/762854547/03e1aa3924 ",//'https://storage.googleapis.com/expn-metaverse.appspot.com/liveChat/3.mp4',//
      buttons: [
        {
          id: "yes",
          text: "Yes",
          title: "",
          type: "video",
          link: Endpoints.chatBotQuestion2Video,
        },
        {
          id: "no",
          text: "No",
          title:
            "It was nice meeting you, Do come again for some interesting cricket trivia and don't forget to follow all the live action on ESPNcricinfo",
          type: "end",
        },
      ],
    },
  },
  question3: {
    id: "question3",
    name: "Raunak Kapoor",
    greetings: "",
    currentTime: new Date().getTime(),
    type: "question",
    btnDisable: false,
    questionData: {
      title:
        "Did you know that Yuvraj Singh holds the record for the fastest fifty in T20 WC?",
      link: "https://vimeo.com/762855154/a480cd2580 ",//'https://storage.googleapis.com/expn-metaverse.appspot.com/liveChat/5.mp4,',//

      buttons: [
        {
          id: "yes",
          text: "Yes",
          title: "",
          type: "video",
          link: Endpoints.chatBotQuestion3Video,
        },
        {
          id: "no",
          text: "No",
          title:
            "It was nice meeting you, Do come again for some interesting cricket trivia and don't forget to follow all the live action on ESPNcricinfo",
          type: "end",
        },
      ],
    },
  },
  end: {
    id: "end",
    name: "Raunak Kapoor",
    link: "https://vimeo.com/762854759/818b479812",//'https://storage.googleapis.com/expn-metaverse.appspot.com/liveChat/6.mp4',//
    greetings:
      "It was nice meeting you, Do come again for some interesting cricket trivia and don't forget to follow all the live action on ESPNcricinfo",
    currentTime: new Date().getTime(),
    type: "end",
    btnDisable: false,
  },
};
