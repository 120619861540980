import { useContext, useEffect, useState } from "react";
import { AVATAR_MODAL_TYPE } from "../../constant/TypeConstant";
import CreateAvatar from "./partials/CreateAvatar";
import ReactAudioPlayer from "react-audio-player";
import styles from "./index.module.scss";
import { AvatarContext } from "../../contexts/AvatarContextProvider";
import StartModal from "./partials/StartModal";
import { LottieTut } from "../../Components";
import Intro from "./partials/Intro/Intro";
import Home from "../Home";
import { Endpoints } from "../../constant/EndPoints";
import CreateAvatarHOC from "./partials/CreateAvatarHOC";
import { bgMusic } from "../../constant/AssetsConstant";

const showRights = [
  AVATAR_MODAL_TYPE.START_EXPERIENCE,
  AVATAR_MODAL_TYPE.CREATE_START,
];

const AvatarModal = () => {
  const { state, switchActiveComponent, bgAudioRef } =
    useContext(AvatarContext);
  const [canvasMount, setCanvasMount] = useState(false);

  useEffect(() => {
    if (state.activeComponent == AVATAR_MODAL_TYPE.CANVAS_LOADING) {
      setCanvasMount(true);
    }
  }, [state.activeComponent]);

  return (
    <div
      className={`modalOverlay ${styles.mainContainer} ${
        state.activeComponent === AVATAR_MODAL_TYPE.CREATE_START &&
        styles.createAvatarDesktop
      } ${
        (state.activeComponent === AVATAR_MODAL_TYPE.CANVAS_LOADING ||
          state.activeComponent === AVATAR_MODAL_TYPE.HOME) &&
        styles.canvasLoading
      }`}
    >
      <CreateAvatarHOC
        hideAll={
          state.activeComponent === AVATAR_MODAL_TYPE.START_EXPERIENCE ||
          state.activeComponent === AVATAR_MODAL_TYPE.CANVAS_LOADING ||
          state.activeComponent === AVATAR_MODAL_TYPE.LOTTIE_TUT
        }
        hideSideMenu={
          state.activeComponent === AVATAR_MODAL_TYPE.HOME ||
          state.activeComponent === AVATAR_MODAL_TYPE.LOTTIE_TUT
        }
      >
        <iframe
          title="ESPN"
          className={styles.canvas}
          src={Endpoints.canvasLink}
          allow="camera;microphone"
        ></iframe>
      </CreateAvatarHOC>
      {state.activeComponent !== AVATAR_MODAL_TYPE.CREATE_START &&
        state.activeComponent !== AVATAR_MODAL_TYPE.CANVAS_LOADING && (
          <div className={styles.container}>
            <SwitchComponent
              type={state.activeComponent}
              switchComponent={switchActiveComponent}
            />
            {showRights.map(
              (comp, index) =>
                state.activeComponent === comp && (
                  <p className={styles.rights} key={index}>
                    &copy; ESPN Digital Media (India) Private Limited,
                    <br />
                    All Rights Reserved
                  </p>
                )
            )}
          </div>
        )}
      {state.activeComponent !== AVATAR_MODAL_TYPE.LOTTIE_TUT &&
        state.activeComponent !== AVATAR_MODAL_TYPE.HOME && (
          <div
            style={{
              visibility:
                state.activeComponent === AVATAR_MODAL_TYPE.CANVAS_LOADING
                  ? "visible"
                  : "hidden",
            }}
          >
            <Intro switchComponent={switchActiveComponent} />
          </div>
        )}
      {/* {
        canvasMount &&
        <iframe
          style={{
            display: state.activeComponent == AVATAR_MODAL_TYPE.HOME ? 'block' : 'none'
          }}
          className={styles.canvas}
          src={Endpoints.canvasLink} allow="camera;microphone"
        ></iframe>
      } */}

      <ReactAudioPlayer
        src={bgMusic}
        volume={0.1}
        loop={true}
        preload="auto"
        ref={bgAudioRef}
      />
      {state.activeComponent === AVATAR_MODAL_TYPE.START_EXPERIENCE && (
        <p className={styles.bottomTagLine}>
          For best user experience, we recommend to use high speed internet.
        </p>
      )}
    </div>
  );
};
const SwitchComponent = ({ type, switchComponent }) => {
  switch (type) {
    case AVATAR_MODAL_TYPE.START_EXPERIENCE:
      return <StartModal switchComponent={switchComponent} />;

    // case AVATAR_MODAL_TYPE.CREATE_START:
    //   return <CreateAvatar switchComponent={switchComponent} />;
    // case AVATAR_MODAL_TYPE.CANVAS_LOADING:
    //   return <Intro switchComponent={switchComponent} />;

    case AVATAR_MODAL_TYPE.LOTTIE_TUT:
      return <LottieTut switchComponent={switchComponent} />;
    case AVATAR_MODAL_TYPE.HOME:
      return <Home switchComponent={switchComponent} />;

    default:
      break;
  }
};
export default AvatarModal;
