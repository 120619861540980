import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Endpoints } from "../constant/EndPoints";
import { MediaModalContext } from "./MediaModalContextProvider";
import { ContentType } from "../Components/MediaModal/MediaModal";
import {
  ADMIN_CONTROL_DOC,
  BACKSTAGE_COLLECTION,
} from "../constant/CollectionConstants";
import { firestore } from "../Firebase";
import { defaultChatBotData } from "../constant/ChatBotConstant";

export const UIContext = createContext(null);

export const MenuStates = {
  participants: 1,
  publicChat: 2,
  polls: 3,
  notification: 4,
  map: 5,
};

export const DEFAULT_ROOM_NAME = "call-room-test";

export const SCENE_DATA = {
  avatar: {
    id: "avatar",
    src: "/avatar/index.html",
  },
  lobby: {
    id: "lobby",
    src: "/3dScene/index.html",
  },
  library: {
    id: "library",
    src: "/library/index.html",
  },
  audi: {
    id: "audi",
    src: "/auditorium/index.html",
  },
  exhibit: {
    id: "exhibit",
    src: "/exhibition/index.html",
  },
  temp: {
    id: "temp",
    src: "",
  },
};

var BoothAcitvity = {
  DEFAULT: 0,
  PHOTOBOOTH: 1,
  GAME_ZONE: 2,
  EXHIBITION: 3,
  BOOTH_1: 4,
  BOOTH_2: 5,
  KIOSK: 6,
};

export const UIContextProvider = (props) => {
  const [mapLocation, setMapLocation] = useState(1); //MenuStates.participants
  const [environmentLoaded, toggleEnvLoaded] = useState(false); //MenuStates.participants
  const [loadingPercentage, setLoadingPercentage] = useState(1); //MenuStates.participants
  const [isMenuOpen, toggleMenuOpen] = useState(null); //MenuStates.participants
  const [activeMenu, setActiveMenu] = useState(null); //MenuStates.participants
  const [videocall, setVideocall] = useState(null);
  const [activeForm, setActiveForm] = useState(null);
  const [currentScene, setCurrentScene] = useState(SCENE_DATA.avatar);
  const [pollQNAState, setPollQNAState] = useState({
    activeMenu: MenuStates.polls,
    isOpen: false,
  });
  const userColorClassRecord = useRef(
    localStorage.getItem("participantColor")
      ? JSON.parse(localStorage.getItem("participantColor"))
      : {}
  );

  const { showMediaModal, closeMediaModal } = useContext(MediaModalContext);

  const [connectedStatus, toggleConnectedStatus] = useState(true);
  const connectedStatusPrevValue = useRef(true);
  const [connectivityScreen, toggleConnectivityScreen] = useState(false);
  const [isChatBotOpen, setIsChatBotOpen] = useState(false);
  const [chatBotData, setChatBotData] = useState([]);
  const [backstageAdminData, setBackstageAdminData] = useState(null);

  useEffect(() => {
    let listenerRef = null;
    try {
      listenerRef = firestore
        .collection(BACKSTAGE_COLLECTION)
        .doc(ADMIN_CONTROL_DOC)
        .onSnapshot((documentSnapshot) => {
          if (documentSnapshot.exists) {
            let data = documentSnapshot.data();
            setBackstageAdminData(data);
          } else {
            setBackstageAdminData(null);
          }
        });
    } catch (error) {
      setBackstageAdminData(null);
      console.error("Error :: ", error);
    }
    return () => {
      if (listenerRef) {
        listenerRef();
      }
    };
  }, []);

  const isVideoUpdatedRef = useRef({ id: "" });

  useEffect(() => {
    if (currentScene.id == SCENE_DATA.temp.id) {
      setCurrentScene(SCENE_DATA.lobby);
    }
  }, [currentScene]);

  useEffect(() => {
    if (connectedStatus == true && connectedStatusPrevValue.current == false) {
      window.dispatchEvent(
        new CustomEvent("connection:regain", {
          bubbles: true,
          detail: { val: { connectedStatus } },
        })
      );
      setCurrentScene(SCENE_DATA.temp);
      toggleConnectivityScreen(false);
    } else if (
      connectedStatus == false &&
      connectedStatusPrevValue.current == true
    ) {
      window.dispatchEvent(
        new CustomEvent("connection:lost", {
          bubbles: true,
          detail: { val: { connectedStatus } },
        })
      );
      toggleConnectivityScreen(true);
    }
    return () => {
      connectedStatusPrevValue.current = connectedStatus;
    };
  }, [connectedStatus]);

  useMemo(() => {
    if (!localStorage.getItem("returingUser")) {
      window.localStorage.setItem("returingUser", "true");
      window.localStorage.setItem("tutorialDone", "false");
      window.localStorage.setItem("welcomeVO", "true");
    }
    if (localStorage.getItem("avatarDone") === "true") {
      setCurrentScene(SCENE_DATA.lobby);
    }
  }, []);

  useEffect(() => {
    window.connectToVideocallRoom = connectToVideocallRoom;
    window.parent.connectToVideocallRoom = connectToVideocallRoom;
    window.parent.switchSceneInformer = updateScene;
    window.parent.toggleConnectivityStatus = (val) => {
      toggleConnectedStatus(val);
    };

    //close Trainign Room
    window.parent.closeTraningRoom = () => {
      window.parent.ToggleTimeScale(1);
      window.parent.connectToVideocallRoom();
      if (window.parent.traniningRoomCloseCallback)
        window.parent.traniningRoomCloseCallback();
    };
    window.parent.openTraningRoom = () => {
      window.parent.ToggleTimeScale(0);
      window.parent.connectToVideocallRoom("TrainingRoom");
      // window.parent.openIframe("TrainingRoom", Endpoints.Exihibition, ContentType.FullIframe);
    };

    window.parent.openIframe = (iName, iUrl, iType, cb) => {
      showMediaModal(
        {
          type: iType,
          link: iUrl,
          name: iName,
        },
        cb
      );

      if (typeof window.parent.tutorialThreeCompleteHard !== "undefined")
        window.parent.tutorialThreeCompleteHard();
    };
    window.parent.closeMediaModal = closeMediaModal;

    window.parent.openActivity = (
      activityIndex,
      activityConfig = { subIndex: 0, callback: () => { } }
    ) => {
      console.log(activityIndex, activityConfig);
      const { subIndex, callback } = activityConfig;
      switch (activityIndex) {
        case BoothAcitvity.GAME_ZONE:
          // window.parent.ToggleTimeScale(0);
          // window.parent.openIframe(
          //   "jigsaw",
          //   Endpoints.jigsaw,
          //   ContentType.Iframe,
          //   () => window.parent.ToggleTimeScale(1)
          // );
          if (window.parent.platformAnalytics) {
            window.parent.platformAnalytics.addHotspotAnalytics("Game");
          }
          break;
        case BoothAcitvity.PHOTOBOOTH:
          window.parent.ToggleTimeScale(0);
          window.parent.openIframe(
            "Photobooth",
            Endpoints.BG_Photobooth,
            ContentType.Iframe,
            () => window.parent.ToggleTimeScale(1)
          );
          if (window.parent.platformAnalytics) {
            window.parent.platformAnalytics.addHotspotAnalytics("PhotoBooth");
          }
          break;
        case BoothAcitvity.EXHIBITION:
          window.parent.ToggleTimeScale(0);
          window.parent.openIframe(
            "EXHIBITION",
            Endpoints.portfolio,
            ContentType.Iframe,
            () => window.parent.ToggleTimeScale(1)
          );

          break;
        case BoothAcitvity.BOOTH_1:
          window.parent.ToggleTimeScale(0);
          if (subIndex == 0) {
            // window.parent.openIframe(
            //   "BOOTH_1_video",
            //   "https://vimeo.com/646650824",
            //   ContentType.Video,
            //   () => window.parent.ToggleTimeScale(1)
            // );
            window.parent.openIframe(
              "BOOTH_1_video",
              Endpoints.YoutubePlaylist,
              ContentType.Iframe,
              () => window.parent.ToggleTimeScale(1)
            );
            if (window.parent.platformAnalytics) {
              window.parent.platformAnalytics.addHotspotAnalytics(
                "VideoHotspot"
              );
            }
          } else if (subIndex == 1) {
            window.parent.openIframe(
              "BOOTH_1_video",
              Endpoints.YoutubePlaylist,
              ContentType.Iframe,
              () => window.parent.ToggleTimeScale(1)
            );
            // window.parent.openIframe(
            //   "BOOTH_1_pdf",
            //   "https://productimageserver.com/literature/brochure/50363BR.pdf",
            //   ContentType.Pdf,
            //   () => window.parent.ToggleTimeScale(1)
            // );
          } else {
            window.parent.ToggleTimeScale(1);
          }
          break;
        case BoothAcitvity.BOOTH_2:
          window.parent.ToggleTimeScale(0);
          if (subIndex == 0) {
            window.parent.openIframe(
              "BOOTH_1_video",
              Endpoints.YoutubePlaylist,
              ContentType.Iframe,
              () => window.parent.ToggleTimeScale(1)
            );

            // window.parent.openIframe(
            //   "BOOTH_2_video",
            //   "/assets/pdfs/dataNft.pdf",
            //   ContentType.Pdf,
            //   () => window.parent.ToggleTimeScale(1)
            // );
          } else if (subIndex == 1) {
            window.parent.openIframe(
              "BOOTH_1_video",
              Endpoints.YoutubePlaylist,
              ContentType.Iframe,
              () => window.parent.ToggleTimeScale(1)
            );
            // window.parent.openIframe(
            //   "BOOTH_2_pdf",
            //   "/assets/pdfs/dataNft.pdf",
            //   ContentType.Pdf,
            //   () => window.parent.ToggleTimeScale(1)
            // );
          } else {
            window.parent.ToggleTimeScale(1);
          }
          break;
        case BoothAcitvity.KIOSK:
          window.parent.ToggleTimeScale(0);
          if (subIndex == 0) {
            window.parent.openIframe(
              "KIOSK_1_video",
              "https://vimeo.com/679481128/26d99069b4",
              ContentType.Video,
              () => window.parent.ToggleTimeScale(1)
            );
          } else if (subIndex == 1) {
            window.parent.openIframe(
              "KIOSK_2_pdf",
              "/assets/pdfs/meta_info.pdf",
              ContentType.Pdf,
              () => window.parent.ToggleTimeScale(1)
            );
          } else if (subIndex == 2) {
            window.parent.openIframe(
              "KIOSK_3_pdf",
              "/assets/pdfs/meta_info.pdf",
              ContentType.Pdf,
              () => window.parent.ToggleTimeScale(1)
            );
          } else {
            window.parent.ToggleTimeScale(1);
          }
          break;
        default:
          console.error("please use vaild activityIndex");
      }
    };
  }, []);

  const getParticipantColorNumber = (id) => {
    let mainObject = userColorClassRecord.current;
    if (!mainObject.hasOwnProperty(id)) {
      let val = Math.floor(Math.random() * 3);
      mainObject[id] = val;
      localStorage.setItem(
        "participantColor",
        JSON.stringify(userColorClassRecord.current)
      );
    }
    return mainObject[id];
  };

  const connectToVideocallRoom = (
    roomId = null,
    temporaryRoom = false,
    sideLayout = false
  ) => {
    console.log(roomId, temporaryRoom, sideLayout);
    if (!roomId) {
      // setActiveMenu(MenuStates.participants)
    } else {
      if (typeof window.parent.connectToVideocallRoomInformer != "undefined")
        window.parent.connectToVideocallRoomInformer();
    }
    toggleMenuOpen(null);
    if (roomId) {
      if (sideLayout) {
        if (window.parent.closePrivateChatRoom)
          window.parent.closePrivateChatRoom();
        setActiveMenu(MenuStates.participants);
      }
      setVideocall({
        roomId: roomId, //"test-ilex-room-1",//roomId,
        temporaryRoom, //temporaryRoom ? 'true' : 'false',
        sideLayout,
      });
      if (!sideLayout)
        if (typeof window.parent.hideInLobby != "undefined")
          window.parent.hideInLobby();
    } else {
      setVideocall(null);
      if (typeof window.parent.showInLobby != "undefined")
        window.parent.showInLobby();
    }
  };

  const closeVideocallRoom = () => {
    connectToVideocallRoom();
  };

  const updateScene = (sceneId) => {
    if (SCENE_DATA.hasOwnProperty(sceneId)) {
      // if (sceneId == "library") {
      //     if (typeof window.parent.hideInLobby != "undefined")
      //         window.parent.hideInLobby();
      // } else if (sceneId == "lobby") {
      //     if (typeof window.parent.showInLobby != "undefined")
      //         window.parent.showInLobby();
      // }else{
      //     if (typeof window.parent.hideInLobby != "undefined")
      //     window.parent.hideInLobby();
      // }

      if (sceneId == "audi") {
        if (window.parent.platformAnalytics) {
          window.parent.platformAnalytics.addHotspotAnalytics("Audi");
        }
      }

      if (typeof window.parent.tutorialThreeCompleteHard !== "undefined")
        window.parent.tutorialThreeCompleteHard();
      console.log("switching scene to " + sceneId);
      // setTimeout(() => {
      setCurrentScene(SCENE_DATA[sceneId]);
      // }, 500);
    } else {
      console.error("No such scene exists: " + sceneId);
    }
  };

  const lastVal = useRef(MenuStates.participants);

  const toggleSideMenu = () => {
    // console.log("toggle"
    setPollQNAState({ activeMenu: MenuStates.polls, isOpen: false });
    toggleMenuOpen((prev) => !prev);
    setActiveMenu((prev) => {
      // console.log(prev)
      if (prev === 0 || prev) {
        lastVal.current = prev;
        // console.log("set null")
        return prev;
      } else {
        // console.log("set prev")
        return lastVal.current ? lastVal.current : MenuStates.participants;
      }
    });
  };

  const openSideMenu = (val = true) => {
    toggleMenuOpen(val);
    setActiveMenu((prev) => {
      // console.log(prev)
      if (prev === 0 || prev) {
        lastVal.current = prev;
        // console.log("set null")
        return prev;
      } else {
        // console.log("set prev")
        return lastVal.current ? lastVal.current : MenuStates.participants;
      }
    });
  };

  const togglePollMenu = (changeActiveState) => {
    setPollQNAState((prev) => {
      if (!changeActiveState && prev.isOpen) {
        return { activeMenu: MenuStates.polls, isOpen: false };
      } else if (changeActiveState) {
        return { activeMenu: changeActiveState, isOpen: true };
      } else {
        return { activeMenu: MenuStates.polls, isOpen: true };
      }
    });
  };

  const toggleChatBot = (bool) => {
    setIsChatBotOpen(bool);
  };

  const updateChatBotData = (data, btn, type, resetData) => {
    setChatBotData((prev) => {
      let newArray = resetData ? [] : [...prev];
      let currentTime = new Date().getTime();

      switch (type) {
        case "optionSelect":

          let replyData = { ...btn };
          replyData.currentTime = currentTime;

          // add final reply data to new data
          let dataToUpdateIndex = newArray.findIndex(
            (item) => item.id === data.id
          );

          let newDataToAppend = newArray[dataToUpdateIndex];
          newDataToAppend.currentTime = currentTime;

          // let introReply = {
          //   id: `${newDataToAppend.id}_Reply`,
          //   name: newDataToAppend.name,
          //   currentTime: newDataToAppend.currentTime,
          //   optionType: replyData.type,
          //   optionTitle: replyData.title,
          //   type: "reply",
          // };
          // if (replyData.id !== "no") {
          //   introReply.optionLink = replyData.link;
          // }
          // newArray.push(introReply);
          newDataToAppend.reply = replyData;
          newDataToAppend.btnDisable = true;

          // isVideoUpdatedRef.current.id = introReply.id;
          isVideoUpdatedRef.current = { id: "" };


          if (replyData.id == "no") {
            newArray.push({ ...defaultChatBotData.end });
          } else {
            switch (data.id) {
              case "intro":
                newArray.push({ ...defaultChatBotData.question1 });
                break;
              case "question1":
                newArray.push({ ...defaultChatBotData.question2 });
                break;
              case "question2":
                newArray.push({ ...defaultChatBotData.question3 });
                break;
              case "question3":
                newArray.push({ ...defaultChatBotData.end });
                break;

              default:
                break;
            }
          }


          break;

        case "addNewData":
          isVideoUpdatedRef.current = { id: "" };
          newArray.push({ ...data });
          break;
        default:
          break;
      }
      let scrollToBottomRef = document.getElementById("chatBotBody");
      setTimeout(() => {
        if (scrollToBottomRef) {
          scrollToBottomRef.style.scrollBehavior = "smooth";
          scrollToBottomRef.scrollTop = scrollToBottomRef.scrollHeight;
        }
      }, 300);
      return newArray;
    });
  };
  const updateNewDataInChatBot = (data, resetData) => {
    setIsChatBotOpen((prev) => (!prev ? true : prev));

    updateChatBotData(data, null, "addNewData", resetData);
  };

  const setDefaultActiveMenu = () => {
    setActiveMenu(MenuStates.participants);
  };

  return (
    <UIContext.Provider
      value={{
        isMenuOpen,
        toggleMenuOpen,
        openSideMenu,
        activeMenu,
        setActiveMenu,
        getParticipantColorNumber,
        videocall,
        connectToVideocallRoom,
        updateScene,
        currentScene,
        closeVideocallRoom,
        toggleSideMenu,
        togglePollMenu,
        pollQNAState,
        connectivityScreen,
        connectedStatus,

        //current project use
        setMapLocation,
        mapLocation,
        loadingPercentage,
        setLoadingPercentage,
        environmentLoaded,
        toggleEnvLoaded,
        isChatBotOpen,
        toggleChatBot,
        chatBotData,
        updateChatBotData,
        updateNewDataInChatBot,
        isVideoUpdatedRef,
        backstageAdminData,
        setDefaultActiveMenu,
      }}
    >
      {props.children}
    </UIContext.Provider>
  );
};
