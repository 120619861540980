import { useEffect, useState } from "react";
import { useRef } from "react";
import { useContext } from "react";
import ReactPlayer from "react-player";
import { defaultChatBotData } from "../../../constant/ChatBotConstant";
import { AvatarContext } from "../../../contexts/AvatarContextProvider";
import { MediaModalContext } from "../../../contexts/MediaModalContextProvider";
import { UIContext } from "../../../contexts/UIContextProvider";
import { ContentType } from "../../MediaModal/MediaModal";
import {
  ExitScreenIcon,
  FullFullScreen,
  PauseIcon,
  PlayIcon,
} from "../../SVGIcons";
import styles from "./index.module.scss";

const ChatBotVideo = ({ link, item }) => {
  const { updateNewDataInChatBot, isVideoUpdatedRef, isChatBotOpen, chatBotData } =
    useContext(UIContext);
  const { playBGAudio, stopBGAudio } = useContext(AvatarContext);
  const { showMediaModal } = useContext(MediaModalContext);
  const [videoDuration, setVideoDuration] = useState(0);
  const [fullScreenOpen, setFullScreenOpen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const [loop, setLoop] = useState(true);



  useEffect(() => {
    if (chatBotData) {
      if (chatBotData.length > 0) {
        if (chatBotData[chatBotData.length - 1].id != item.id) {
          setIsPlaying(false)
        }
      }
    }
  }, [chatBotData]);
  useEffect(() => {
    setTimeout(() => {
      setLoop(false)
    }, 200);
  }, []);

  useEffect(() => {
    if (isPlaying) {
      stopBGAudio();
    } else {
      playBGAudio();
    }
    return () => {
      playBGAudio();
    };
  }, [isPlaying]);

  useEffect(() => {
    if (!isChatBotOpen) {
      setIsPlaying(false);
    }
  }, [isChatBotOpen]);

  useEffect(() => {
    if (fullScreenOpen) {
      showMediaModal({ type: ContentType.Video, link: link }, (cb) => {
        toggleFullScreen();
      });
    }
  }, [fullScreenOpen]);

  let lastProgress = useRef(-1)
  const handleOnProgress = (props) => {
    const { playedSeconds } = props;

    let percentagePlayed = (playedSeconds / videoDuration) * 100;
    console.log(percentagePlayed)
    return;
    if (percentagePlayed <= lastProgress.current || percentagePlayed >= 99) {
      togglePlayPause();
      lastProgress.current = -1
    } else {
      lastProgress.current = percentagePlayed
    }
    // if (percentagePlayed >= 70) {
    //   switch (item.id) {
    //     case "intro_Reply":
    //       if (isVideoUpdatedRef.current.id === "intro_Reply") {
    //         updateNewDataInChatBot(defaultChatBotData.question1, false);
    //       }
    //       break;
    //     case "question1_Reply":
    //       if (isVideoUpdatedRef.current.id === "question1_Reply") {
    //         updateNewDataInChatBot(defaultChatBotData.question2, false);
    //       }
    //       break;
    //     case "question2_Reply":
    //       if (isVideoUpdatedRef.current.id === "question2_Reply") {
    //         updateNewDataInChatBot(defaultChatBotData.question3, false);
    //       }
    //       break;
    //     case "question3_Reply":
    //       if (isVideoUpdatedRef.current.id === "question3_Reply") {
    //         updateNewDataInChatBot(defaultChatBotData.end, false);
    //       }
    //       break;

    //     default:
    //       break;
    //   }
    // }
  };

  const toggleFullScreen = () => {
    setFullScreenOpen((prev) => !prev);
  };

  const togglePlayPause = () => {
    setIsPlaying((prev) => !prev);
  };

  return (
    <div className={styles.container}>
      <ReactPlayer
        playing={isPlaying}
        url={link}
        playsinline={true}
        autoplay={true}
        volume={!fullScreenOpen ? 0.85 : 0}
        // controls={false}
        width="100%"
        height="100%"
        loop={loop}
        onDuration={(d) => setVideoDuration(d)}
        // onProgress={handleOnProgress}
        config={{
          vimeo: {
            autopause: true,
            loop: false,
            autoplay: true
          },
        }}
        style={{
          background: "black",
          borderRadius: "0.5rem",
          aspectRatio: "16/9",
        }}
        onEnded={() => {
          setIsPlaying(false);
        }}
      />
      <div
        className={`${styles.icons} ${styles.playPause}`}
        onClick={togglePlayPause}
      >
        {isPlaying ? <PauseIcon /> : <PlayIcon />}
      </div>
      <div
        className={`${styles.icons} ${styles.fullScreen}`}
        onClick={toggleFullScreen}
      >
        {fullScreenOpen ? <ExitScreenIcon /> : <FullFullScreen />}
      </div>
    </div>
  );
};

export default ChatBotVideo;
