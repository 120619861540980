import axios from "axios";
import { auth, CallCloudFunction } from ".";
import { CloudFunctionName } from "../constant/CloudFunctionName";
import { Endpoints } from "../constant/EndPoints";

export function updateName(user, name) {
  if (!user.displayName) {
    user.updateProfile({
      displayName: name,
    });
  }
}

export const signInWithId = (email, password) => {
  return new Promise(async (res, rej) => {
    try {
      const cred = await auth.signInWithEmailAndPassword(email, password);
      console.log(cred.user.email + " is logged in right now");

      res();
    } catch (error) {
      rej(error);
    }
  });
};

const defaultImageUrl =
  "https://firebasestorage.googleapis.com/v0/b/djfarmademo.appspot.com/o/profileimages%2Fblank-avatar.png?alt=media&token=2af15226-9bd7-47ce-bc72-f3c1a12a0780";

export const signUpWithId = (email, password, name) => {
  return new Promise(async (res, rej) => {
    try {
      const response = await auth.createUserWithEmailAndPassword(
        email,
        password
      );
      await response.user.updateProfile({
        displayName: name,
        photoURL: defaultImageUrl,
      });
      res();
    } catch (error) {
      rej(error);
    }
  });
};

export const loadUser = async (email, name, forceCreateNew = false) => {
  return new Promise(async (res, rej) => {
    try {
      email = email.toLowerCase().trim();
      console.log("loadUser");
      await signInWithId(email, `${email}123456`);
      res();
    } catch (err) {
      if (err.code === "auth/user-not-found" && forceCreateNew) {
        try {
          console.log("caling func");
          await CallCloudFunction(CloudFunctionName.createMetaUser, {
            email,
            name,
          });
          loadUser(email, name);
          // await auth.createUserWithEmailAndPassword(email, `${email}123456`);

          // const response = await axios.post(
          //     Endpoints.signup
          //     , { name, email })
          // if (response.data.status === "success") {
          //     await signInWithId(email, `${email}123456`);
          // }
          // await signUpWithId(email, password, name);
          return;
        } catch (error) {
          if (error.code === "user not found") {
            console.log(error);
            rej(error);
          }
        }
      }
      console.log(err);
      rej({
        code: err.code,
        message: err.message,
      });
    }
  });
};

export const signOut = (noRefresh) => {
  if (window.parent.platformAnalytics) {
    window.parent.platformAnalytics.addLogoutAnalytics();
  }
  auth.signOut().then(function () {
    if (!noRefresh) {
      window.sessionStorage.removeItem("sId");
      window.location.reload();
      window.location.href = "/";
    }
  });
};

export const loadUser_Server = async (email, name) => {
  return new Promise(async (res, rej) => {
    try {
      console.log("loadUser");
      const response = await axios.post(Endpoints.signup, { name, email });
      if (response.data.status === "success") {
        await signInWithId(email, `${email}123456`);
      }
      res();
    } catch (err) {
      console.log(err);
      rej({
        code: err.code,
        message: err.message,
      });
    }
  });
};
