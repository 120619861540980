import { useEffect } from "react";
import MediaModal from "./Components/MediaModal/MediaModal";
import { AvatarContextProvider } from "./contexts";
import ChatContextProvider from "./contexts/ChatContextProvider";
import MediaModalContextProvider from "./contexts/MediaModalContextProvider";
import NotificationContextProvider from "./contexts/NotificationContextProvider";
import PlaycanvasConnector from "./contexts/PlaycanvasConnector";
import { UIContextProvider } from "./contexts/UIContextProvider";
import UserContextProvider from "./contexts/UserContextProvider";
import { RenderPages } from "./Pages";
import Test from "./Pages/test";

const App = () => {
  useEffect(() => {
    //comment in dev
    window.console.log = () => {};
    appHeight();
    window.addEventListener("resize", appHeight);
    return () => {
      window.removeEventListener("resize", appHeight);
    };
  }, []);

  const appHeight = () => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty(
      "--app-height",
      `calc(var(${vh}px, 1vh) * 100)`
    );
  };

  return (
    <UserContextProvider>
      <MediaModalContextProvider>
        <NotificationContextProvider>
          <UIContextProvider>
            <ChatContextProvider>
              <AvatarContextProvider>
                <PlaycanvasConnector>
                  <MediaModal />
                  {/* <Test /> */}
                  <RenderPages />
                </PlaycanvasConnector>
              </AvatarContextProvider>
            </ChatContextProvider>
          </UIContextProvider>
        </NotificationContextProvider>
      </MediaModalContextProvider>
    </UserContextProvider>
  );
};

export default App;
