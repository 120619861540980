import {
  AVATAR_ASSET_CATEGORY,
  AVATAR_MALE_ACCESSORIES,
} from "./AssetsConstant";

export const AVATAR_MODAL_TYPE = {
  START_EXPERIENCE: "start_experience",
  CREATE_START: "create_start",
  CANVAS_LOADING: "canvas_loading",
  LOTTIE_TUT: "lottie_tut",
  HOME: "home",
};

export const CREATE_AVATAR = {
  SCREEN_FIRST: "screen_first",
  SCREEN_SECOND: "screen_second",
};

export const assetsType = {
  hair: {
    enable: true,
    id: "hair",
    title: "hair",
    img: AVATAR_ASSET_CATEGORY.hair,
    styles: {
      hair1: {
        id: "hair1",
        title: "hair1",
        img: {
          male: AVATAR_MALE_ACCESSORIES.mhair.hair1,
          female: AVATAR_MALE_ACCESSORIES.fhair.hair1,
        },
      },
      hair2: {
        id: "hair2",
        title: "hair2",
        img: {
          male: AVATAR_MALE_ACCESSORIES.mhair.hair2,
          female: AVATAR_MALE_ACCESSORIES.fhair.hair2,
        },
      },
      hair3: {
        id: "hair3",
        title: "hair3",
        img: {
          male: AVATAR_MALE_ACCESSORIES.mhair.hair3,
          female: AVATAR_MALE_ACCESSORIES.fhair.hair3,
        },
      },
    },
    colors: {
      color1: { id: "color1", color: "#000000FF", title: "color1" },
      color2: { id: "color2", color: "#633519FF", title: "color2" },
      color3: { id: "color3", color: "#FFCA7AFF", title: "color3" },
      color4: { id: "color4", color: "#8C8D8EFF", title: "color4" },
    },
  },
  face: {
    enable: true,
    id: "face",
    title: "face",
    img: AVATAR_ASSET_CATEGORY.face,
    styles: {},
    colors: {
      color1: { id: "color1", color: "#DC9E83FF", title: "color1" },
      color2: { id: "color2", color: "#FF9171FF", title: "color2" },
      color3: { id: "color3", color: "#D39C6CFF", title: "color3" },
      color4: { id: "color4", color: "#A36542FF", title: "color4" },
      color5: { id: "color5", color: "#6C4F45FF", title: "color5" },
      color6: { id: "color6", color: "#82513CFF", title: "color6" },
      color6: { id: "color7", color: "#F47750FF", title: "color7" },
      color6: { id: "color8", color: "#8C5D34FF", title: "color8" },
    },
  },
  tShirt: {
    enable: true,
    id: "tShirt",
    title: "tShirt",
    img: AVATAR_ASSET_CATEGORY.tShirt,
    styles: {
      tShirt1: {
        id: "tShirt1",
        title: "tShirt",
        img: AVATAR_MALE_ACCESSORIES.tShirt.tShirt1,
      },
      tShirt2: {
        id: "tShirt2",
        title: "tShirt",
        img: AVATAR_MALE_ACCESSORIES.tShirt.tShirt2,
      },
      tShirt3: {
        id: "tShirt3",
        title: "tShirt",
        img: AVATAR_MALE_ACCESSORIES.tShirt.tShirt3,
      },
      tShirt4: {
        id: "tShirt4",
        title: "tShirt",
        img: AVATAR_MALE_ACCESSORIES.tShirt.tShirt4,
      },
      tShirt5: {
        id: "tShirt5",
        title: "tShirt",
        img: AVATAR_MALE_ACCESSORIES.tShirt.tShirt5,
      },
      tShirt6: {
        id: "tShirt6",
        title: "tShirt",
        img: AVATAR_MALE_ACCESSORIES.tShirt.tShirt6,
      },
    },
    colors: {},
  },
  specs: {
    enable: true,
    id: "specs",
    title: "specs",
    img: AVATAR_ASSET_CATEGORY.specs,
    styles: {
      specs1: {
        id: "specs1",
        title: "specs",
        img: AVATAR_MALE_ACCESSORIES.specs.specs1,
      },
      specs2: {
        id: "specs2",
        title: "specs",
        img: AVATAR_MALE_ACCESSORIES.specs.specs2,
      },
      specs3: {
        id: "specs3",
        title: "specs",
        img: AVATAR_MALE_ACCESSORIES.specs.specs3,
      },
    },
    colors: {},
  },
};
