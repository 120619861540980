import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/analytics";
import "firebase/functions";
import { CloudFunctionName } from "../constant/CloudFunctionName";
import { RealtimeDatabasePaths } from "../constant/RealtimePath";
import {
  ASSETS_COLLECTION,
  ASSETS_COLLECTION_DOC,
  ASSETS_VEHICLE_COLLECTION_DOC,
  AUDI_DOC,
  BACKSTAGE_COLLECTION,
} from "../constant/CollectionConstants";
import { AppString } from "./constant";
import { ContentType } from "../Components/MediaModal/MediaModal";

const firebaseConfig = {
  apiKey: "AIzaSyBFUMWjonZjVvgxn2QOVpO5pSS55ANSGJM",
  authDomain: "expn-metaverse.firebaseapp.com",
  databaseURL:
    "https://expn-metaverse-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "expn-metaverse",
  storageBucket: "expn-metaverse.appspot.com",
  messagingSenderId: "197743052515",
  appId: "1:197743052515:web:8a4a0f661c92b69f20a7d6",
  measurementId: "G-G63KJMVFQ6",
};

firebase.initializeApp(firebaseConfig);

export default firebase;
export const firestore = firebase.firestore();
export const database = firebase.database();
export const analytics = firebase.analytics();
export const auth = firebase.auth();
auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
export const cloudFunction_as = firebase.app().functions("asia-south1");
// cloudFunction_as.useEmulator('localhost', 5001);

export const CallCloudFunction = (name, payload) => {
  const cfRef = cloudFunction_as.httpsCallable(name);
  return cfRef(JSON.stringify(payload));
};

export const GetDailycoToken = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await CallCloudFunction(
        CloudFunctionName.generateVideocallToken,
        payload
      );
      resolve(response.data.token);
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export function login(name, password) {
  auth
    .signInWithEmailAndPassword(name, password)
    .then((userCred) => {
      console.log(userCred);
    })
    .catch((err) => console.log(err))
    .finally((a) => console.log(a, "finaly"));
}

export const checkUserRoleFromRDB = (userid = "") => {
  return new Promise(async (resolve, reject) => {
    try {
      let _userid = userid.replace(/[^\w\s]/gi, "");
      let _nodelink = `${RealtimeDatabasePaths.userRoles}/${_userid}`;
      let nodeRef = database.ref(_nodelink);
      const node = await nodeRef.get();
      if (node.exists) {
        resolve(node.val());
      } else {
        resolve(null);
      }
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

//#region dailyco functions

export const checkForDailycoAdmin = (userToken, roomId) => {
  return new Promise(async (res, rej) => {
    try {
      const videoCallDoc = await firestore
        .collection(AppString.Dailyco_Col)
        .doc(roomId)
        .get();
      let docData = videoCallDoc.data();
      if (!docData || !docData.admins.includes(userToken.uid)) {
        rej({
          code: "NoAdmin",
          messsage: "this user is not a admin",
        });
      }
      delete docData.users;
      delete docData.admins;
      delete docData.callStarted;

      res(docData);
    } catch (error) {
      rej(error);
    }
  });
};

export const checkForDailycoMember = async (userToken) => {
  const dailycoRoomDocs = await firestore
    .collection(AppString.Dailyco_Col)
    .where("members", "array-contains", userToken.uid)
    .get();
  if (dailycoRoomDocs.empty) {
    throw "No room found for user";
  }

  for (let i = 0; i < dailycoRoomDocs.docs.length; i++) {
    if (dailycoRoomDocs.docs[i].id.includes("Breakout")) {
      return dailycoRoomDocs.docs[i].id;
    }
  }

  throw "No room found for user";
};

export const getDailycoRoomDetails = (roomId) => {
  return new Promise(async (res, rej) => {
    try {
      const videoCallDoc = await firestore
        .collection(AppString.Dailyco_Col)
        .doc(roomId)
        .get();

      let data = videoCallDoc.data();
      console.log(data);
      if (!data) {
        console.log("NoVideoRoomFound");
        rej({
          code: "NoVideoRoom",
          messsage: "NoVideoRoomFound",
        });
      }
      res(data);
    } catch (error) {
      rej(error);
    }
  });
};

export const checkDailycoRoomStatus = (room) => {
  return new Promise(async (res, rej) => {
    try {
      const videoCallDoc = await firestore
        .collection(AppString.Dailyco_Col)
        .doc(room)
        .get();
      if (!videoCallDoc.exists) {
        rej({
          code: "NoVideoRoom",
          messsage: "NoVideoRoomFound",
        });
      }
      console.log(videoCallDoc.data());
      res(videoCallDoc.data().callStarted);
    } catch (error) {
      rej(error);
    }
  });
};

//#endregion

// #region backstage

let listenerRef = null;

export const getAudiData = async (
  callback = () => console.log("noFunFound")
) => {
  try {
    listenerRef = firestore
      .collection(BACKSTAGE_COLLECTION)
      .doc(AUDI_DOC)
      .onSnapshot((documentSnapshot) => {
        if (documentSnapshot.exists) {
          let data = documentSnapshot.data();
          callback(data);
        } else {
          callback(null);
        }
      });
  } catch (error) {
    callback(null);
    console.error("Error :: ", error);
  }
};

export const removeAudiListener = () => {
  if (listenerRef) {
    listenerRef();
  }
};

// #endregion backstage

export const getAvatarDetails = async function (userid) {
  return new Promise((resolve, reject) => {
    try {
      let realTimeDB = database;
      let _path = RealtimeDatabasePaths.AvatarDetails + "/" + userid;
      let _nodeRef = realTimeDB.ref(_path);
      _nodeRef.once("value", (snapshot) => {
        if (snapshot.exists()) {
          resolve(snapshot.val());
        } else {
          resolve(null);
        }
      });
    } catch (err) {
      console.error(err);
      reject(err);
    }
  });
};

export const addAssets = function (data = {
  canvasLink: "",
  canvasType: "",
  link: "",
  type: "",
  title: "",
  id: ""
}) {
  return new Promise(async (resolve, reject) => {
    try {
      let docRef = firestore.collection(ASSETS_COLLECTION).doc(ASSETS_COLLECTION_DOC);
      await docRef.update({
        [data.id]: {
          ...data,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        }
      });
      resolve();
    } catch (error) {
      console.error(error)
      reject(error);
    }
  })

};


export const getVehiclePictures = function () {
  return new Promise(async (resolve, reject) => {
    try {
      let docRef = firestore.collection(ASSETS_COLLECTION).doc(ASSETS_VEHICLE_COLLECTION_DOC);
      let snap = await docRef.get();
      resolve(snap.data());
    } catch (error) {
      console.error(error)
      reject(error);
    }
  })
};

window.addAssets = async () => {

  // let tickerAPI = 'https://pp-hs-consumer-api.espncricinfo.com/v1/edition/details?edition=in&trendingMatches=true&lang=en';
  // // fetch(tickerAPI).then((response) => { console.log(response) }, (r) => console.log(r));
  // fetch(tickerAPI)
  //   .then((response) => response.json())
  //   .then((data) => console.log(data));
  // // headers: {
  // //   'x-hsci-internal': 'true'
  // //    },

  //Lobby Area Poster Screen
  // await addAssets({
  //   canvasLink: "https://storage.googleapis.com/virtual-event-273009.appspot.com/EspnVideo/template%20(1).jpg",
  //   canvasType: ContentType.Image,
  //   link: "https://storage.googleapis.com/virtual-event-273009.appspot.com/EspnVideo/template%20(1).jpg",
  //   type: ContentType.Image,
  //   title: "lobby_leftScreen",
  //   id: "lobby_leftScreen"
  // });
  // await addAssets({
  //   canvasLink: "https://storage.googleapis.com/virtual-event-273009.appspot.com/EspnVideo/template%20(1).jpg",
  //   canvasType: ContentType.Image,
  //   link: "https://storage.googleapis.com/virtual-event-273009.appspot.com/EspnVideo/template%20(1).jpg",
  //   type: ContentType.Image,
  //   title: "lobby_rightScreen",
  //   id: "lobby_rightScreen"
  // });

  //Brand ROom Screens
  // await addAssets({
  //   canvasLink: "https://storage.googleapis.com/virtual-event-273009.appspot.com/EspnVideo/GV_Banner_1080x1920-KV-AllGrip%20(1).jpg",
  //   canvasType: ContentType.Image,
  //   link: "https://storage.googleapis.com/virtual-event-273009.appspot.com/EspnVideo/GV_Banner_1080x1920-KV-AllGrip%20(1).jpg",
  //   type: ContentType.Image,
  //   title: "Brand Room Screen_Left_1",
  //   id: "Brand Room Screen_Left_1"
  // });

  // await addAssets({
  //   canvasLink: "https://storage.googleapis.com/virtual-event-273009.appspot.com/EspnVideo/GV_Banner_1080x1920-KV-IEH%20(1).jpg",
  //   canvasType: ContentType.Image,
  //   link: "https://storage.googleapis.com/virtual-event-273009.appspot.com/EspnVideo/brandRoomSideScreens.jpg",
  //   type: ContentType.Image,
  //   title: "Brand Room Screen_Right_1",
  //   id: "Brand Room Screen_Right_1"
  // });

  // await addAssets({
  //   canvasLink: "https://storage.googleapis.com/virtual-event-273009.appspot.com/EspnVideo/brandRoomSideScreens.jpg",
  //   canvasType: ContentType.Image,
  //   link: "https://storage.googleapis.com/virtual-event-273009.appspot.com/EspnVideo/brandRoomSideScreens.jpg",
  //   type: ContentType.Image,
  //   title: "Brand Room Screen_Left_2",
  //   id: "Brand Room Screen_Left_2"
  // });

  // await addAssets({
  //   canvasLink: "https://storage.googleapis.com/virtual-event-273009.appspot.com/EspnVideo/brandRoomSideScreens.jpg",
  //   canvasType: ContentType.Image,
  //   link: "https://storage.googleapis.com/virtual-event-273009.appspot.com/EspnVideo/brandRoomSideScreens.jpg",
  //   type: ContentType.Image,
  //   title: "Brand Room Screen_Right_2",
  //   id: "Brand Room Screen_Right_2"
  // });

  //Live Room Space
  // await addAssets({
  //   canvasLink: "https://storage.googleapis.com/virtual-event-273009.appspot.com/EspnVideo/liveRoomSideScreen.jpg",
  //   canvasType: ContentType.Image,
  //   link: "https://storage.googleapis.com/virtual-event-273009.appspot.com/EspnVideo/liveRoomSideScreen.jpg",
  //   type: ContentType.Image,
  //   title: "Live Show Screen_Left",
  //   id: "Live Show Screen_Left"
  // });

  // await addAssets({
  //   canvasLink: "https://storage.googleapis.com/virtual-event-273009.appspot.com/EspnVideo/liveRoomSideScreen.jpg",
  //   canvasType: ContentType.Image,
  //   link: "https://storage.googleapis.com/virtual-event-273009.appspot.com/EspnVideo/liveRoomSideScreen.jpg",
  //   type: ContentType.Image,
  //   title: "Live Show Screen_Right",
  //   id: "Live Show Screen_Right"
  // });

  //Play Room Space
  // await addAssets({
  //   canvasLink: "https://storage.googleapis.com/virtual-event-273009.appspot.com/EspnVideo/gameRoomPoster.jpg",
  //   canvasType: ContentType.Image,
  //   link: "https://storage.googleapis.com/virtual-event-273009.appspot.com/EspnVideo/gameRoomPoster.jpg",
  //   type: ContentType.Image,
  //   title: "Play_Area_Screen_Right",
  //   id: "Play_Area_Screen_Right"
  // });

  // await addAssets({
  //   canvasLink: "https://storage.googleapis.com/virtual-event-273009.appspot.com/EspnVideo/gameRoomPoster.jpg",
  //   canvasType: ContentType.Image,
  //   link: "https://storage.googleapis.com/virtual-event-273009.appspot.com/EspnVideo/gameRoomPoster.jpg",
  //   type: ContentType.Image,
  //   title: "Play_Area_Screen_Left",
  //   id: "Play_Area_Screen_Left"
  // });

  //Stats Room Space
  // await addAssets({
  //   canvasLink: "https://storage.googleapis.com/virtual-event-273009.appspot.com/EspnVideo/StatsRoom1.jpg",
  //   canvasType: ContentType.Image,
  //   link: "https://storage.googleapis.com/virtual-event-273009.appspot.com/EspnVideo/StatsRoom1.jpg",
  //   type: ContentType.Image,
  //   title: "Stats Room Screen_1",
  //   id: "Stats Room Screen_1"
  // });
  // await addAssets({
  //   canvasLink: "https://storage.googleapis.com/virtual-event-273009.appspot.com/EspnVideo/StatsRoom1.jpg",
  //   canvasType: ContentType.Image,
  //   link: "https://storage.googleapis.com/virtual-event-273009.appspot.com/EspnVideo/StatsRoom1.jpg",
  //   type: ContentType.Image,
  //   title: "Stats Room Screen_6",
  //   id: "Stats Room Screen_6"
  // });

  // await addAssets({
  //   canvasLink: "https://storage.googleapis.com/virtual-event-273009.appspot.com/EspnVideo/StatsRoom2.jpg",
  //   canvasType: ContentType.Image,
  //   link: "https://storage.googleapis.com/virtual-event-273009.appspot.com/EspnVideo/StatsRoom2.jpg",
  //   type: ContentType.Image,
  //   title: "Stats Room Screen_2",
  //   id: "Stats Room Screen_2"
  // });
  // await addAssets({
  //   canvasLink: "https://storage.googleapis.com/virtual-event-273009.appspot.com/EspnVideo/StatsRoom5.jpg",
  //   canvasType: ContentType.Image,
  //   link: "https://storage.googleapis.com/virtual-event-273009.appspot.com/EspnVideo/StatsRoom5.jpg",
  //   type: ContentType.Image,
  //   title: "Stats Room Screen_5",
  //   id: "Stats Room Screen_5"
  // });

  // await addAssets({
  //   canvasLink: "https://storage.googleapis.com/virtual-event-273009.appspot.com/EspnVideo/StatsRoom3.jpg",
  //   canvasType: ContentType.Image,
  //   link: "https://storage.googleapis.com/virtual-event-273009.appspot.com/EspnVideo/StatsRoom3.jpg",
  //   type: ContentType.Image,
  //   title: "Stats Room Screen_3",
  //   id: "Stats Room Screen_3"
  // });

  // await addAssets({
  //   canvasLink: "https://storage.googleapis.com/virtual-event-273009.appspot.com/EspnVideo/StatsRoom3.jpg",
  //   canvasType: ContentType.Image,
  //   link: "https://storage.googleapis.com/virtual-event-273009.appspot.com/EspnVideo/StatsRoom3.jpg",
  //   type: ContentType.Image,
  //   title: "Stats Room Screen_4",
  //   id: "Stats Room Screen_4"
  // });

  const VideoFormatType = {
    "rss": "rss",
    "mp4": "mp4"
  };


  // await addAssets({
  //   canvasLink: "https://storage.googleapis.com/virtual-event-273009.appspot.com/EspnVideo/template%20(2).jpg",
  //   canvasType: ContentType.Image,
  //   link: "https://storage.googleapis.com/virtual-event-273009.appspot.com/EspnVideo/template%20(2).jpg",
  //   type: ContentType.Image,
  //   title: "Lobby_Big_Screen1",
  //   id: "Lobby_Big_Screen1"
  // });

  // await addAssets({
  //   canvasLink: "https://storage.googleapis.com/virtual-event-273009.appspot.com/EspnVideo/template%20(2).jpg",
  //   canvasType: ContentType.Image,
  //   link: "https://storage.googleapis.com/virtual-event-273009.appspot.com/EspnVideo/template%20(2).jpg",
  //   type: ContentType.Image,
  //   title: "Lobby_Big_Screen2",
  //   id: "Lobby_Big_Screen2"
  // });


  // await addAssets({
  //   canvasLink: "https://storage.googleapis.com/virtual-event-273009.appspot.com/espn/ESPNcricinfo%20%20%23CricketsBiggestFanClub.mp4",//"http://media.video-origin.espn.com/espnvideo/fastclipper/2022/1011/evc__20221011_no_event_name_55c988b4_1da7_45d2_b2d5_94adf0878e8d_15961/evc__20221011_no_event_name_55c988b4_1da7_45d2_b2d5_94adf0878e8d_15961.mp4",
  //   canvasType: ContentType.Video,
  //   canvasVideoType: VideoFormatType.mp4,
  //   link: "https://vimeo.com/562838474",
  //   type: ContentType.Video,
  //   title: "Lobby_Big_Screen1",
  //   id: "Lobby_Big_Screen1"
  // });


  // await addAssets({
  //   canvasLink: "https://storage.googleapis.com/virtual-event-273009.appspot.com/espn/ESPNcricinfo%20%20%23CricketsBiggestFanClub.mp4",//"http://media.video-origin.espn.com/espnvideo/fastclipper/2022/1011/evc__20221011_no_event_name_55c988b4_1da7_45d2_b2d5_94adf0878e8d_15961/evc__20221011_no_event_name_55c988b4_1da7_45d2_b2d5_94adf0878e8d_15961.mp4",
  //   canvasType: ContentType.Video,
  //   canvasVideoType: VideoFormatType.mp4,
  //   link: "https://vimeo.com/562838474",
  //   type: ContentType.Video,
  //   title: "Lobby_Big_Screen2",
  //   id: "Lobby_Big_Screen2"
  // });



  // await addAssets({
  //   canvasLink: "https://storage.googleapis.com/virtual-event-273009.appspot.com/EspnVideo/30%20Secs.mp4",//"http://media.video-origin.espn.com/espnvideo/fastclipper/2022/1011/evc__20221011_no_event_name_55c988b4_1da7_45d2_b2d5_94adf0878e8d_15961/evc__20221011_no_event_name_55c988b4_1da7_45d2_b2d5_94adf0878e8d_15961.mp4",
  //   canvasType: ContentType.Video,
  //   canvasVideoType: VideoFormatType.mp4,
  //   link: "https://vimeo.com/562838474",
  //   type: ContentType.Video,
  //   title: "liveRoomScreen01",
  //   id: "liveRoomScreen01"
  // });

  //brandRoomScreen01
  //liveRoomScreen01

  await addAssets({
    canvasLink: "https://www.espncricinfo.com/rss/content/video_audio/feeds.rss?client=cricinfoverse",
    canvasType: ContentType.Video,
    canvasVideoType: VideoFormatType.rss,
    link: "https://vimeo.com/724622846",
    status: true,
    type: ContentType.Video,
    title: "brandRoomScreen01",
    id: "brandRoomScreen01"
  });

  // await addAssets({
  //   canvasLink: "https://storage.googleapis.com/expn-metaverse.appspot.com/initalsAssets/Timeout%20MS%20Sting%20and%20endplate_half.mp4",
  //   canvasType: ContentType.Video,
  //   canvasVideoType: VideoFormatType.mp4,
  //   link: "https://vimeo.com/762872600/33b3405f38",
  //   status: true,
  //   type: ContentType.Video,
  //   title: "liveRoomScreen01",
  //   id: "liveRoomScreen01"
  // });

}

window.parent.updateLiveMp4 = async (canvasLink, vimeolink) => {
  const VideoFormatType = {
    "rss": "rss",
    "mp4": "mp4"
  };

  await addAssets({
    canvasLink: canvasLink,
    canvasType: ContentType.Video,
    canvasVideoType: VideoFormatType.mp4,
    link: vimeolink,
    status: true,
    type: ContentType.Video,
    title: "brandRoomScreen01",
    id: "brandRoomScreen01"
  });
}

window.parent.updateLiveRss = async (rssLink) => {
  const VideoFormatType = {
    "rss": "rss",
    "mp4": "mp4"
  };

  await addAssets({
    canvasLink: rssLink,//"https://www.espncricinfo.com/rss/content/video_audio/feeds.rss?client=cricinfoverse",
    canvasType: ContentType.Video,
    canvasVideoType: VideoFormatType.rss,
    link: "https://vimeo.com/762872600/33b3405f38",
    status: true,
    type: ContentType.Video,
    title: "brandRoomScreen01",
    id: "brandRoomScreen01"
  });

}