import React, { useContext, useRef } from "react";

// import Emoji from "react-emoji-render";
import { Emojione } from "react-emoji-render";
import { AUDIO_CLIP_DATA } from "../../constant/AssetsConstant";
import { UIContext } from "../../contexts/UIContextProvider";
import { ChatTextStyle } from "../../utils";

import playIcon from "../../assets/images/play.png";
import pauseIcon from "../../assets/images/pause.png";
import { useState } from "react";
import { useEffect } from "react";
import { secondsToHms } from "../../utils/commonUtils";
import { AvatarContext } from "../../contexts/AvatarContextProvider";
import { GET_AUDIO_FROM_ID } from "../../constant/MessageClipData";

const Message = ({ type, name, message, userId, time }) => {
  const { getParticipantColorNumber } = useContext(UIContext);

  // const initals = useRef(getInitals(name))
  // const avartStyleRandom = useRef(Math.floor(Math.random() * 3))
  const avartStyleRandom = useRef(getParticipantColorNumber(userId));
  return (
    <div className="chat-section__text">
      {/* <div className="chat-section__text-header">
                <div className={`user-icon ${UserIconStyle[avartStyleRandom.current]} `}>{initals.current}</div>
                <div>
                    <h3>{name}</h3>
                </div>
            </div> */}
      <div className="chat-section__text-body">
        <h3
          className={`chat-user-title ${
            ChatTextStyle[avartStyleRandom.current]
          } `}
        >
          {name}
        </h3>
        {/* <h4>Participant</h4> */}
        <div
          className={`main-message-container ${
            message.length === 2 ? "bigEmoji" : ""
          } `}
        >
          {type === "audio" ? (
            <AudioMessage text={message} />
          ) : (
            <Emojione text={message} />
          )}

          {/* {message} */}
        </div>
        <p className="chat-time">
          <small>
            {time &&
              new Date(time).toLocaleTimeString("en-US", {
                timeStyle: "short",
              })}
          </small>
        </p>
      </div>
    </div>
  );
};

const AudioMessage = ({ text }) => {
  const audioRef = useRef(null);
  const { playBGAudio, stopBGAudio } = useContext(AvatarContext);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [audioTimeUpdate, setAudioTimeUpdate] = useState(0);
  const [audioDuration, setAudioDuration] = useState(0);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener("play", () => {
        stopBGAudio();
        setIsAudioPlaying(true);
      });
      audioRef.current.addEventListener("pause", () => {
        playBGAudio();
        setIsAudioPlaying(false);
      });
      audioRef.current.addEventListener("canplaythrough", (e) => {
        setAudioDuration(e.target.duration);
      });
      audioRef.current.addEventListener("timeupdate", (e) => {
        setAudioTimeUpdate(e.target.currentTime);
      });
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener("play", () => {
          stopBGAudio();
          setIsAudioPlaying(true);
        });
        audioRef.current.removeEventListener("pause", () => {
          playBGAudio();
          setIsAudioPlaying(false);
        });
        audioRef.current.removeEventListener("canplaythrough", (e) => {
          setAudioDuration(e.target.duration);
        });
        audioRef.current.removeEventListener("timeupdate", (e) => {
          setAudioTimeUpdate(e.target.currentTime);
        });
      }
    };
  }, [audioRef]);

  const toggleAudioPlay = () => {
    if (audioRef.current) {
      if (audioRef.current.paused) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
        color: "#fff",
      }}
    >
      <div
        style={{
          background: "#000",
          borderRadius: "50%",
          width: "1.5rem",
          height: "1.5rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
        onClick={toggleAudioPlay}
      >
        <img
          src={!isAudioPlaying ? playIcon : pauseIcon}
          alt=""
          width={"50%"}
        />
      </div>
      <div
        style={{
          backgroundColor: "#202020",
          height: "0.5rem",
          width: "8rem",
        }}
      >
        <div
          style={{
            backgroundColor: "#fff",
            transition: "0.1s",
            height: "100%",
            width: `${(audioTimeUpdate / audioDuration) * 100}%`,
          }}
        />
      </div>
      <div>
        {`0${secondsToHms(audioTimeUpdate)}`}:
        {`0${secondsToHms(audioDuration)}`}
      </div>
      <audio
        src={GET_AUDIO_FROM_ID[text]}
        preload="auto"
        ref={audioRef}
      ></audio>
    </div>
  );
};

export const ReceivedMsg = React.memo(
  ({ id, type, name, message, userId, time }) => {
    return (
      <div
        id={id}
        className="chat-section__message chat-section__message--received"
      >
        <Message
          type={type}
          name={name}
          message={message}
          userId={userId}
          time={time}
        />
      </div>
    );
  }
);

export const SentMsg = React.memo(
  ({ id, type, name, message, userId, time }) => {
    return (
      <div
        id={id}
        className="chat-section__message chat-section__message--sent"
      >
        <Message
          type={type}
          name={`${name} (You)`}
          message={message}
          userId={userId}
          time={time}
        />
      </div>
    );
  }
);
