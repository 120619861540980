import moment from "moment";
import React, { useContext } from "react";

import { UIContext } from "../../contexts/UIContextProvider";
import { UserContext } from "../../contexts/UserContextProvider";
import CloseIcon from "../SVGIcons";
import ChatBotVideo from "./ChatBotVideo";
import styles from "./index.module.scss";

const ChatBot = () => {
  const { chatBotData, updateChatBotData, toggleChatBot, isChatBotOpen } =
    useContext(UIContext);

  return (
    <aside
      className={`${styles["container"]} ${isChatBotOpen ? "d-flex" : "d-none"
        }`}
    >
      <header>
        <div className={styles.title}>Live Chat</div>
        <div
          className={`${styles.closeIcon}`}
          onClick={() => toggleChatBot(false)}
        >
          <CloseIcon />
        </div>
      </header>
      <div className={styles.body} id="chatBotBody">
        {chatBotData.map((item, index) => (
          <React.Fragment key={index}>
            <ChatBotItem item={item} handleBtnCLick={updateChatBotData} />
            {item.reply && <ChatBotReply item={item} />}
          </React.Fragment>
        ))}
      </div>
    </aside>
  );
};

export default ChatBot;

const ChatBotItem = ({ item, handleBtnCLick }) => {
  const {
    name,
    greetings,
    currentTime,
    type,
    btnDisable,
    questionData,
    optionType,
    optionLink,
    optionTitle,
  } = item;

  return (
    <div className={styles.sidebarItem}>
      {name && <p className={styles.name}>{name}</p>}
      {/* {greetings && <p className={styles.greetings}>{greetings}</p>} */}

      {type === "question" ? (
        <div className={styles.questionContainer}>
          {
            questionData.link ?
              <ChatBotVideo link={questionData.link} item={item} />
              :
              <p className={styles.questionTitle}>{questionData.title}</p>
          }
          <div className={styles.btnContainer}>
            {questionData.buttons.map((btn, index) => (
              <button
                onClick={() => {

                  handleBtnCLick(item, btn, "optionSelect")
                }}
                key={index}
                disabled={btnDisable}
                className={`btn ${styles.btn}`}
              >
                {btn.text}
              </button>
            ))}
          </div>
        </div>
      ) : type === "reply" ? (
        <div className={styles.replyContainer}>
          <p className={styles.questionTitle}>{optionTitle}</p>
          {/* {optionType === "video" ? (
            <ChatBotVideo link={optionLink} item={item} />
          ) : null} */}
        </div>
      ) : (
        type === "end" ? (
          <>
            <div className={styles.questionContainer}>
              <ChatBotVideo link={item.link} item={item} />
            </div>
          </>
        ) : null
      )}
      <div className={styles.time}>{moment(currentTime).format("LT")}</div>
    </div>
  );
};

const ChatBotReply = ({ item }) => {
  const { user } = useContext(UserContext);
  const { reply } = item;

  return (
    <div className={`${styles.sidebarItem} ${styles.sidebarReply}`}>
      <p className={styles.name}>{user?.displayName}</p>
      <p className={styles.greetings}>{reply.text}</p>
      <div className={styles.time}>
        {moment(reply.currentTime).format("LT")}
      </div>
    </div>
  );
};
