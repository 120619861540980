import firebase, { database } from ".";
import { RealtimeDatabasePaths } from "../constant/RealtimePath";

import { AppString } from "./constant";

export const user_ActiveStatus = (
  user,
  nodeAddress = AppString.USER_STATUS
) => {
  let isOfflineForDatabase = {
    StateMode: "offline",
  };
  let isOnlineForDatabase = {
    StateMode: "online",
  };
  let userStatusDatabaseRef = database.ref(
    RealtimeDatabasePaths.chat + "/" + nodeAddress + "/" + cleanEmail(user.uid)
  );
  let onDisconnectRef = userStatusDatabaseRef.onDisconnect();
  onDisconnectRef.remove().then(() => {
    let userData = {
      userName: user.displayName,
      fullName: user.displayName,
      name: user.displayName,
      id: user.uid,
      email: user.email,
      userid: user.email,
    };
    userStatusDatabaseRef.set({
      ...isOnlineForDatabase,
      ...userData,
    });
  });
};

export async function getUserDetails(user) {
  return new Promise((res, rej) => {
    let email = user.email;
    email = email.replace(/[&\/\\#,+$~%.'":*?<>{}]/g, "");
    email = email.toLowerCase();
    let userRef = database.ref(`/user/${email}`);
    userRef.once(
      "value",
      async (snapshot) => {
        if (snapshot.exists()) {
          res(snapshot.val());
        } else {
          throw new Error({ code: "NotFound", message: "" });
        }
      },
      (err) => {
        console.log(err);
        rej(err);
      }
    );
  });
}
export async function UpdateUserTable(user) {
  return new Promise((res, rej) => {
    let userRef = database.ref(
      `${RealtimeDatabasePaths.chat}/${AppString.USERS_DOC_REALTIME_DB}/${user.uid}`
    );
    userRef.once(
      "value",
      async (snapshot) => {
        let userData = {
          userName: user.displayName,
          fullName: user.displayName,
          name: user.displayName,
          id: user.uid,
          email: user.email,
          userid: user.email,
        };
        if (user.photoURL) {
          userData = {
            ...userData,
            profile_picture: user.photoURL,
          };
        } else {
          userData = {
            ...userData,
            profile_picture: AppString.DEFAULT_AVATAR,
          };
        }
        await userRef.set(userData);

        // if (!snapshot.exists()) {

        // } else {
        //     //Check Default Image Is Their or not
        //     if (user.photoURL) {
        //         await userRef.update({ profile_picture: user.photoURL });
        //     } else {
        //         await userRef.update({ profile_picture: AppString.DEFAULT_AVATAR });
        //     }
        // }
        res();
      },
      (err) => {
        console.log(err);
        rej(err);
      }
    );
  });
}

export async function getOnlineUsers(roomName, callback) {
  let userRef = database.ref(`${RealtimeDatabasePaths.chat}/${roomName}`); //${AppString.USER_STATUS}`)
  userRef.on(
    "value",
    async (snapshot) => {
      if (snapshot.exists()) {
        if (callback) {
          callback(snapshot.val());
        }
      } else {
        if (callback) {
          callback([], { code: "NotFound" });
        }
      }
    },
    (err) => {
      if (callback) {
        callback([], err);
      }
    }
  );
}

export const getOnlineUsersList = ({
  roomName = AppString.USER_STATUS,
  addCallback = () => {},
  removeCallback = () => {},
}) => {
  let userRef = database.ref(`${RealtimeDatabasePaths.chat}/${roomName}`);
  userRef.on("child_added", (data) => {
    addCallback(data.val());
  });
  userRef.on("child_removed", (data) => {
    removeCallback(data.val());
  });
};

export async function getAllParticipants(callback) {
  let userRef = database
    .ref(`${RealtimeDatabasePaths.chat}/${AppString.USERS_DOC_REALTIME_DB}`)
    .orderByChild("email");
  userRef.on(
    "value",
    async (snapshot) => {
      if (snapshot.exists()) {
        let result = {};
        let count = 1;
        snapshot.forEach((child) => {
          // console.log(child.key);
          // console.log(child.val());
          result[`${count}-${child.key}`] = child.val();
          count++;
        });
        // console.log(result)
        if (callback) {
          callback(result);
        }
      } else {
        if (callback) {
          callback([], { code: "NotFound" });
        }
      }
    },
    (err) => {
      if (callback) {
        callback([], err);
      }
    }
  );
}

export async function getParticipantList(callback) {
  let userRef = database
    .ref(`${RealtimeDatabasePaths.chat}/${AppString.USERS_DOC_REALTIME_DB}`)
    .orderByChild("email");
  userRef.on("child_added", (data) => {
    callback(data.val());
  });
}

export function cleanEmail(val) {
  val = val.replace(/[&\/\\#,+$~%.'":*?<>{}]/g, "");
  return val.toLowerCase();
}

export function getRoomIdWithUser(currentUserId, otherUserId) {
  currentUserId = cleanEmail(currentUserId);
  otherUserId = cleanEmail(otherUserId);
  let id = "";
  if (currentUserId > otherUserId) {
    id = currentUserId + "+" + otherUserId;
  } else {
    id = otherUserId + "+" + currentUserId;
  }
  return id;
}

export async function getOldMessagesFromRoom(roomId) {
  return new Promise((response, reject) => {
    let userRef = database.ref(
      `${RealtimeDatabasePaths.chat}/messages/${roomId}`
    );
    userRef.on(
      "value",
      async (snapshot) => {
        if (snapshot.exists()) {
          response(snapshot.val());
        } else {
          response([]);
        }
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function attachNewMessagesListener(refVar, roomId, callback) {
  // console.log(firebase.database.ServerValue.TIMESTAMP);
  let userRef = database
    .ref(`${RealtimeDatabasePaths.chat}/${AppString.ROOM_MESSAGES}/${roomId}`)
    .orderByChild("createdAt")
    .startAfter(new Date().getTime() - 60 * 60 * 1000);
  const handleListener = async (snapshot) => {
    if (snapshot.exists()) {
      // console.log(snapshot);
      if (callback) {
        callback({
          ...snapshot.val(),
          messageid: snapshot.key,
        });
      }
    } else {
      if (callback) {
        callback([], { code: "NotFound" });
      }
    }
  };
  userRef.on("child_added", handleListener, (err) => {
    if (callback) {
      callback([], err);
    }
  });
  refVar = () => {
    userRef.off("child_added", handleListener, (err) => {
      if (callback) {
        callback([], err);
      }
    });
  };
}

export function sendMessage1on1(
  currentUserName,
  currentUid,
  otherUserUid,
  otherUserName,
  roomId,
  message,
  type
) {
  let _currentUid = cleanEmail(currentUid);
  let _otherUserUid = cleanEmail(otherUserUid);
  //update your roomList with current roomName
  const roomListRef = database.ref(
    `${RealtimeDatabasePaths.chat}/${AppString.ROOMS_LIST}/${_currentUid}`
  );
  roomListRef.update({
    [roomId]: true,
  });
  //update members in MembersNode
  // const memberNodeRef = database.ref(`${RealtimeDatabasePaths.chat}/${AppString.MEMBERS_LIST}/${roomId}`)
  // memberNodeRef.update({
  //     [currentUid]: true,
  //     [otherUserUid]: true
  // })
  //add message in MessagesNode - {  }
  const messageNodeRef = database
    .ref(`${RealtimeDatabasePaths.chat}/${AppString.ROOM_MESSAGES}/${roomId}`)
    .push();
  messageNodeRef.set({
    name: currentUserName,
    id: currentUid,
    createdAt: firebase.database.ServerValue.TIMESTAMP,
    message: message,
    room: roomId,
    type,
  });
  //update chatRoom meta data under roomMetadataNode
  let chatMetaData = {
    message: message,
    room: roomId,
    createdAt: firebase.database.ServerValue.TIMESTAMP,
    name: currentUserName,
    id: currentUid,
    type,
    [currentUid]: currentUserName,
    [otherUserUid]: otherUserName,
    participants: [currentUid, otherUserUid],
  };
  // console.log(chatMetaData);
  const currentUserRoomMetadataNodeRef = database.ref(
    `${RealtimeDatabasePaths.chat}/${AppString.ROOM_METADATA}/${_currentUid}/${roomId}`
  );
  currentUserRoomMetadataNodeRef.update(chatMetaData);

  const otherUserRoomMetadataNodeRef = database.ref(
    `${RealtimeDatabasePaths.chat}/${AppString.ROOM_METADATA}/${_otherUserUid}/${roomId}`
  );
  otherUserRoomMetadataNodeRef.update(chatMetaData);

  //add notification to other usernode and remove current userNotification if any
  const currentUserNotificationNode = database.ref(
    `${RealtimeDatabasePaths.chat}/${AppString.USER_NOTIFICATION}/${_currentUid}/${_otherUserUid}`
  );
  currentUserNotificationNode.remove();

  const otherUserNotificationNode = database.ref(
    `${RealtimeDatabasePaths.chat}/${AppString.USER_NOTIFICATION}/${_otherUserUid}/${_currentUid}`
  );
  otherUserNotificationNode.update({
    count: firebase.database.ServerValue.increment(1),
    message: message,
    roomId: roomId,
    createdAt: firebase.database.ServerValue.TIMESTAMP,
    name: currentUserName,
    id: currentUid,
    type,
  });
}

export function attachNotificationListener(refVar, userId, callback) {
  let userRef = database.ref(
    `${RealtimeDatabasePaths.chat}/${AppString.USER_NOTIFICATION}/${userId}`
  );
  refVar = userRef;
  userRef.on(
    "value",
    async (snapshot) => {
      if (snapshot.exists()) {
        if (callback) {
          callback(snapshot.val());
        }
      } else {
        if (callback) {
          callback([], { code: "NotFound", message: "No notification found" });
        }
      }
    },
    (err) => {
      if (callback) {
        callback([], err);
      }
    }
  );
}

export function removeNotification(userId, otherUserUid) {
  let userRef = database.ref(
    `${RealtimeDatabasePaths.chat}/${AppString.USER_NOTIFICATION}/${userId}/${otherUserUid}`
  );
  userRef.remove();
}

export function attachTypingIndicator(roomId, otherUserId, refVar, callback) {
  let userRef = database.ref(
    `${RealtimeDatabasePaths.chat}/${AppString.ROOM_TYPING_INDICATOR}/${roomId}/${otherUserId}`
  );
  refVar = userRef;
  userRef.on(
    "value",
    async (snapshot) => {
      if (snapshot.exists()) {
        if (callback) {
          callback(snapshot.val());
        }
      } else {
        if (callback) {
          callback([], { code: "noStatus", message: "No Typing Status found" });
        }
      }
    },
    (err) => {
      if (callback) {
        callback([], err);
      }
    }
  );
}

export function singalTypingIndicator(roomId, userId, value) {
  let userRef = database.ref(
    `${RealtimeDatabasePaths.chat}/${AppString.ROOM_TYPING_INDICATOR}/${roomId}/`
  );
  userRef.update({
    [userId]: value,
  });
}

export function attachOnDisconnectForTyping(refVar, roomId, userId) {
  let userRef = database.ref(
    `${RealtimeDatabasePaths.chat}/${AppString.ROOM_TYPING_INDICATOR}/${roomId}/`
  );
  let disconnectRef = userRef.onDisconnect();
  disconnectRef.update({
    [userId]: false,
  });
  refVar = disconnectRef;
}

export function getUserInteractedRoom(refVar, userId, callback) {
  // console.log(
  //   `${RealtimeDatabasePaths.chat}/${AppString.ROOM_METADATA}/${userId}`
  // );
  let userRef = database
    .ref(
      `${RealtimeDatabasePaths.chat}/${
        AppString.ROOM_METADATA
      }/${userId.toLowerCase()}`
    )
    .orderByChild(`createdAt`);
  refVar = userRef;
  userRef.on(
    "value",
    async (snapshot) => {
      // console.log(userId)
      // console.log(snapshot.val())

      // let result = {}
      // let count = 1;
      // snapshot.forEach((child) => {
      //     console.log(child.key);
      //     console.log(child.val());
      //     result[`${count}-${child.key}`] = child.val()
      //     count++;
      // })

      if (snapshot.exists()) {
        if (callback) {
          callback(snapshot.val());
        }
      } else {
        if (callback) {
          callback([], { code: "noStatus", message: "No Typing Status found" });
        }
      }
    },
    (err) => {
      if (callback) {
        callback([], err);
      }
    }
  );
}

export const userOnlinePresence = (user, roomId, listenerRef) => {
  let isOnlineForDatabase = {
    StateMode: "online",
  };
  let userStatusDatabaseRef = database.ref(
    `${RealtimeDatabasePaths.chat}/${AppString.INCALL_UserPresence}/${roomId}/${user.uid}`
  );
  let onDisconnectRef = userStatusDatabaseRef.onDisconnect();
  listenerRef = onDisconnectRef;
  onDisconnectRef.remove().then(() => {
    userStatusDatabaseRef.set({
      ...isOnlineForDatabase,
      name: user.displayName ?? "",
      id: user.uid,
    });
  });
};

export const goOfflineInRoom = (user, roomId) => {
  let userStatusDatabaseRef = database.ref(
    `${RealtimeDatabasePaths.chat}/${AppString.INCALL_UserPresence}/${roomId}/${user.uid}`
  );
  userStatusDatabaseRef.remove();
};

export const getOnlinPresentUserInRoom = (roomId, refVar, callback) => {
  let userRef = database.ref(
    `${RealtimeDatabasePaths.chat}/${AppString.INCALL_UserPresence}/${roomId}`
  );
  refVar = userRef;
  userRef.on(
    "value",
    async (snapshot) => {
      if (snapshot.exists()) {
        if (callback) {
          callback(snapshot.val());
        }
      } else {
        if (callback) {
          callback([], { code: "NotFound", message: "No notification found" });
        }
      }
    },
    (err) => {
      if (callback) {
        callback([], err);
      }
    }
  );
};

export const userNetConnectionStatus = (callback = () => {}) => {
  var connectedRef = database.ref(".info/connected");
  connectedRef.on("value", (snap) => {
    if (snap.val() === true) {
      console.log("connected to internet +--+/");
      callback(true);
    } else {
      console.log("not connected to internet _--_/");
      callback(false);
    }
  });
};
