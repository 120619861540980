import { useContext } from "react";
import PrivateChat from "../PrivateChat/PrivateChat";
import PublicChat from "../PublicChat/PublicChat";
import { MenuStates, UIContext } from "../../contexts/UIContextProvider";
import styles from "./index.module.css";
import Loader from "../Loader/Loader";
import { UserContext } from "../../contexts/UserContextProvider";
import { ChatContext } from "../../contexts/ChatContextProvider";

export default function Sidebar({ desktop, children }) {
  const { activeMenu, isMenuOpen } = useContext(UIContext);
  const { publicRoomName } = useContext(ChatContext);
  const { user } = useContext(UserContext);

  return (
    <aside
      className={
        desktop
          ? `sidebar ${isMenuOpen ? "active" : ""}`
          : `${styles["container"]} ${isMenuOpen ? "d-flex" : "d-none"}`
      }
    >
      {children}
      {user && user.isChecked ? (
        <>
          {activeMenu === MenuStates.participants && (
            <PrivateChat profanityCheck={true} />
          )}

          {activeMenu === MenuStates.publicChat && (
            <PublicChat
              profanityCheck={true}
              room={{ roomId: publicRoomName }}
            />
            //to change publicRoom from url pass URLParam publicRoom=roomId
          )}
        </>
      ) : (
        <div className={styles.loaderContainer}>
          <Loader />
        </div>
      )}
    </aside>
  );
}
