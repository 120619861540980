import { createContext, useContext, useEffect, useMemo, useRef } from "react";
import { ContentType } from "../Components/MediaModal/MediaModal";
import { vehicleImages } from "../constant/AssetsConstant";
import { defaultChatBotData } from "../constant/ChatBotConstant";
import { Endpoints } from "../constant/EndPoints";
import { getVehiclePictures } from "../Firebase";
import { addGAAnalytics, HotspotIdToAnalyticsName } from "../Firebase/analytics";
import { MediaModalContext } from "./MediaModalContextProvider";
import { UIContext } from "./UIContextProvider";

export const PlaycanvasConnectorContext = createContext();

const CanvasMediaType = {
    'image': 1,
    'video': 2,
    'Three60Image': 3,
    'Game': 4,
    'Link': 5,
}

const parseXML = (_url, cb = () => { }, errCb = () => { }, addCorsServer = false) => {
    const corsURL = "https://cors-anywhere.herokuapp.com/";
    if (addCorsServer) {
        _url = corsURL + _url;
    }
    fetch(_url)
        .then(response => response.text())
        .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
        .then(data => {
            console.log(data);
            let item = data.querySelector("item")
            if (item) {
                let url = item.getElementsByTagNameNS("*", "player").item(0).getAttribute('url');
                cb(url);
            } else {
                let err = data.querySelector("parsererror")
                if (err) {
                    errCb();
                }
            }
        }).catch(err => {
            if (err.message == 'Failed to fetch' && !addCorsServer) {
                parseXML(_url, cb, errCb, true);
            } else {
                console.log(err);
            }
        });
}

export default function PlaycanvasConnector(props) {
    const m_MediaModalContext = useContext(MediaModalContext);
    const m_UIContext = useContext(UIContext);
    const urlRefs = useRef(null);
    useEffect(() => {
        getVehiclePictures().then(data => {
            urlRefs.current = data.urls;
        })
    }, [])

    useMemo(() => {
        window.parent.reactToPc = {};

        //handle Item click
        window.parent.reactToPc.handleCanvasItemClick = function (_data) {
            let { type, link, isStatic } = _data;
            console.log(_data);
            try {
                let id = HotspotIdToAnalyticsName[_data.id];
                if (!id) {
                    id = _data.id;
                }
                if (window.parent.platformAnalytics) {
                    window.parent.platformAnalytics.addHotspotAnalytics(id)
                }
                addGAAnalytics("hotspotInteraction", {
                    id: id,
                    type: _data.type
                })
            } catch (error) {
                console.log("error", error);
            }
            if (isStatic) {
                //can be live chat or person click
                if (_data.id == "LiveChat") {
                    /*
                        _data.id: this.ItemId,
                        title: this.ItemId,
                        isStatic: true
                    */
                    m_UIContext.updateNewDataInChatBot(defaultChatBotData.intro, true)


                } else if (_data.id == "one-on-oneChat") {
                    /*
                        id: 'one-on-oneChat',
                        title: 'one-on-oneChat',
                        userData: this.playerData,
                        isStatic: true
                    */
                    if (_data.userData)
                        if (_data.userData.userid) {
                            m_UIContext.setDefaultActiveMenu();
                            window.parent.openChatWithUser(_data.userData.userid)
                        }
                } else if (_data.id == "game1") {
                    m_MediaModalContext.showMediaModal({ type: ContentType.FullIframe, link: Endpoints.espnGame }, () => { });
                } else if (_data.id == "game2") {
                    m_MediaModalContext.showMediaModal({ type: ContentType.FullIframe, link: Endpoints.game }, () => { });
                } else if (_data.id == "link_askCricinfo") {
                    m_MediaModalContext.showMediaModal({ type: ContentType.FullIframe, link: Endpoints.espncricinfo }, () => { });
                } else if (_data.id == "carHotspot") {
                    if (!_data.subId) {
                        _data.subId = 0;
                    }
                    let link = urlRefs.current ? urlRefs.current[_data.subId - 1] : vehicleImages[_data.subId - 1];
                    m_MediaModalContext.showMediaModal({ type: ContentType.Image, link }, () => {
                        if (window.parent.pcToReact) {
                            if (window.parent.pcToReact.handleModalImageClose) {
                                window.parent.pcToReact.handleModalImageClose();
                            }
                        }
                    });
                }

            } else {
                // console.log("_data", _data)
                // console.log(type, link);
                if (type == "video") {
                    // link = "https://livestream.com/accounts/6115179/events/10657336/player?width=960&height=540&enableInfoAndActivity=true&defaultDrawer=feed&autoPlay=true&mute=false"
                    const VideoFormatType = {
                        "rss": "rss",
                        "mp4": "mp4"
                    };
                    if (_data.canvasVideoType == VideoFormatType.rss) {
                        // console.log('rss video found')
                        parseXML(_data.canvasLink, (_itemLink) => {
                            // console.log('_itemLink', _itemLink);
                            if (_itemLink) {
                                m_MediaModalContext.showMediaModal({ type: type, link: _itemLink }, () => { });
                            } else {
                                if (link.includes('livestream')) {
                                    m_MediaModalContext.showMediaModal({ type: ContentType.Iframe, link }, () => { });
                                } else {
                                    m_MediaModalContext.showMediaModal({ type: type, link: link }, () => { });
                                }
                            }
                        }, () => {
                            console.log('rss video not parsed found')
                            if (link.includes('livestream')) {
                                m_MediaModalContext.showMediaModal({ type: ContentType.Iframe, link }, () => { });
                            } else {
                                m_MediaModalContext.showMediaModal({ type: type, link: link }, () => { });
                            }
                        })
                    } else {
                        //if mp4, run link
                        // console.log('mp4 video found')
                        if (link.includes('livestream')) {
                            m_MediaModalContext.showMediaModal({ type: ContentType.Iframe, link }, () => { });
                        } else {
                            m_MediaModalContext.showMediaModal({ type: type, link: link }, () => { });
                        }
                    }
                } else {
                    m_MediaModalContext.showMediaModal({ type: type, link: link }, () => { });
                }
            }
            return;

            switch (type) {
                case CanvasMediaType.Image:
                    m_MediaModalContext.showMediaModal({ type: ContentType.Image, link: link }, () => { });
                    break;
                case CanvasMediaType.Video:
                    m_MediaModalContext.showMediaModal({ type: ContentType.Video, link: Endpoints.sampleVideo }, () => { });
                    break;
                case CanvasMediaType.Three60Image:
                    m_MediaModalContext.showMediaModal({ type: ContentType.Iframe, link: Endpoints.Three60 }, () => { });
                    break;
                case CanvasMediaType.Game:
                    m_MediaModalContext.showMediaModal({ type: ContentType.FullIframe, link: Endpoints.game }, () => { });
                    break;
                case CanvasMediaType.Link:
                    m_MediaModalContext.showMediaModal({ type: ContentType.Iframe, link: Endpoints.espncricinfo }, () => { });
            }
        }

        //handle Location Change
        window.parent.reactToPc.handleAreaChange = function (areaId) {
            // console.log(areaId);
            m_UIContext.setMapLocation(areaId);
        }

        let nippleInstance = null;

        //share nipple js Instance
        window.parent.reactToPc.getNippleManagerAccess = function () {
            return nippleInstance;
        }

        //set nipple js Instance
        window.parent.reactToPc.setNippleManagerAccess = function (instance) {
            nippleInstance = instance;
        }

        //Update loading status
        window.parent.reactToPc.updateLoadingStatus = function (percentage) {
            // console.log("loading complte", percentage);
            m_UIContext.setLoadingPercentage(percentage);
        }

        //
        window.parent.reactToPc.canvasLoadingComplete = function () {

        }

        window.parent.reactToPc.environmentLoadingComplete = function (val = false) {
            m_UIContext.toggleEnvLoaded(val)
        }


        return true
    }, [])

    return (
        <PlaycanvasConnectorContext.Provider value={{}}>
            {props.children}
        </PlaycanvasConnectorContext.Provider>
    );
}
