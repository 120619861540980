import firebase from ".";
import { database } from ".";

const addNotification = (data) => {
  return new Promise(async (res, rej) => {
    try {
      let { roomid, id } = data;
      let sendData = {
        createdat: firebase.database.ServerValue.TIMESTAMP,
        updatedat: firebase.database.ServerValue.TIMESTAMP,
        ...data,
        deleteStatus: false,
        ispublished: false,
      };
      let ref = database.ref(`dev/notifications/${roomid}/${id}`);
      await ref.set(sendData);
      res();
    } catch (error) {
      console.error(error);
      rej(error);
    }
  });
};

const updateNotification = (data) => {
  return new Promise(async (res, rej) => {
    try {
      let { roomid, id } = data;

      let sendData = {
        ...data,
        updatedat: firebase.database.ServerValue.TIMESTAMP,
      };
      let ref = database.ref(`dev/notifications/${roomid}/${id}`);
      await ref.update(sendData);
    } catch (error) {
      console.error(error);
      rej(error);
    }
  });
};

const togglePublishNotification = (datas) => {
  return new Promise(async (res, rej) => {
    try {
      let { roomid, id, publish } = datas;
      let ref = database.ref(`dev/notifications/${roomid}/${id}`);
      let sendData = {
        ...datas,
        updatedat: firebase.database.ServerValue.TIMESTAMP,
        ispublished: publish,
        publishdat: firebase.database.ServerValue.TIMESTAMP,
      };
      await ref.update(sendData);
      // let snapshot = await ref.once("value");
      // let data = snapshot.val();
      // if (data.publish) {
      //   await ref.update({
      //     publish: false,
      //     updatedAt: firebase.database.ServerValue.TIMESTAMP,
      //   });
      // } else {
      //   await ref.update({
      //     publish: true,
      //     updatedAt: firebase.database.ServerValue.TIMESTAMP,
      //   });
      // }
      res();
    } catch (error) {
      console.error(error);
      rej(error);
    }
  });
};

const deleteNotification = (data) => {
  return new Promise(async (res, rej) => {
    try {
      let { roomid, id } = data;
      let ref = database.ref(`dev/notifications/${roomid}/${id}`);
      let sendData = {
        ...data,
        updatedat: firebase.database.ServerValue.TIMESTAMP,
        deleteStatus: true,
      };
      // await ref.update(sendData);
      await ref.remove();

      res();
    } catch (error) {
      console.error(error);
      rej(error);
    }
  });
};

const addListener = (
  roomid,
  listerRef,
  cb = {
    onCreate: () => { },
    onUpdate: () => { },
    onDelete: () => { },
  }
) => {
  listerRef = database.ref(`dev/notifications/${roomid}`);
  listerRef.on("child_added", (data) => {
    cb.onCreate(data.val());
  });

  listerRef.on("child_changed", (data) => {
    // console.log("child_changed");
    // console.log("child update", data.val());
    cb.onUpdate(data.val());
  });

  listerRef.on("child_removed", (data) => {
    // console.log("child_removed");
    // console.log("child remove", data.val());
    cb.onDelete(data.val());
  });
};

export const NotificationFirebaseManager = {
  addNotification,
  updateNotification,
  togglePublishNotification,
  deleteNotification,
  addListener,
};
