import { useContext } from "react";
import {
  AvatarContext,
  AVATAR_ACTION_TYPE,
} from "../../../../contexts/AvatarContextProvider";
import { handleInputChange, isValidForm } from "../../../../utils/commonUtils";
import { leftArrowIcon, loaderImg } from "../../../../constant/AssetsConstant";
import styles from "./index.module.scss";
import {
  assetsType,
  AVATAR_MODAL_TYPE,
  CREATE_AVATAR,
} from "../../../../constant/TypeConstant";
import { loadUser } from "../../../../Firebase/auth";
import { isMobileOnly } from "react-device-detect";
import { UserContext } from "../../../../contexts/UserContextProvider";
import { CallCloudFunction } from "../../../../Firebase";
import { CloudFunctionName } from "../../../../constant/CloudFunctionName";
import {
  addGAAnalytics,
  AnalyticsEventName,
} from "../../../../Firebase/analytics";
function toggleFullScreen() {
  return openFullscreen();
  var doc = window.document;
  var docEl = doc.documentElement;

  var requestFullScreen =
    docEl.requestFullscreen ||
    docEl.mozRequestFullScreen ||
    docEl.webkitRequestFullScreen ||
    docEl.msRequestFullscreen;
  var cancelFullScreen =
    doc.exitFullscreen ||
    doc.mozCancelFullScreen ||
    doc.webkitExitFullscreen ||
    doc.msExitFullscreen;

  if (
    !doc.fullscreenElement &&
    !doc.mozFullScreenElement &&
    !doc.webkitFullscreenElement &&
    !doc.msFullscreenElement
  ) {
    requestFullScreen.call(docEl);
  } else {
    // cancelFullScreen.call(doc);
  }
}

/* Get the documentElement (<html>) to display the page in fullscreen */
var elem = document.documentElement;

/* View in fullscreen */
const openFullscreen = () => {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.webkitRequestFullscreen) {
    /* Safari */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    /* IE11 */
    elem.msRequestFullscreen();
  }
};
/* Close fullscreen */
const closeFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    /* Safari */
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    /* IE11 */
    document.msExitFullscreen();
  }
};

const inputs = [
  {
    label: "Full Name",
    name: "fullName",
    className: "form-control",
    type: "text",
    placeholder: "Enter Full Name",
    required: true,
    disabled: false,
    readOnly: false,
    maxLength: 20,
  },

  {
    label: "Add Jersey Name",
    name: "jerseyName",
    className: "form-control",
    type: "text",
    placeholder: "Enter Jersey Name",
    required: true,
    disabled: false,
    readOnly: false,
    maxLength: 7,
  },

  {
    label: "Add Jersey Number",
    name: "jerseyNumber",
    className: "form-control",
    type: "number",
    preFun: (event, cb) => {
      event.target.value = event.target.value.toString().substring(0, 2);
      cb(event);
    },
    placeholder: "Enter Jersey Number",
    required: true,
    disabled: false,
    readOnly: false,
    maxLength: 2,
  },
];

const showRights = [
  AVATAR_MODAL_TYPE.START_EXPERIENCE,
  AVATAR_MODAL_TYPE.CREATE_START,
];

const CreateAvatarHOC = (props) => {
  const { state, dispatch } = useContext(AvatarContext);
  const { user } = useContext(UserContext);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      dispatch({
        type: AVATAR_ACTION_TYPE.loading,
        payload: { isLoading: true },
      });
      validateForm();

      if (!isValidForm(state.errors)) {
        dispatch({
          type: AVATAR_ACTION_TYPE.loading,
          payload: { isLoading: false },
        });
        return;
      }
      let obj = {
        fullName: state.fullName,
        jerseyName: state.jerseyName,
        jerseyNumber: state.jerseyNumber,
        gender: state.gender,
      };
      // console.log("next", obj);

      dispatch({
        type: AVATAR_ACTION_TYPE.loading,
        payload: {
          isLoading: false,
          activeScreen: CREATE_AVATAR.SCREEN_SECOND,
        },
      });
    } catch (error) {
      let errors = state.errors;
      if (error.code) {
        errors.description = error.message;
      }
      dispatch({
        type: AVATAR_ACTION_TYPE.loading,
        payload: { isLoading: false, errors: errors },
      });
    }
  };

  const validateForm = () => {
    let errors = state.errors;

    errors.fullName =
      state.fullName.trim().length > 0 ? "" : "Please enter valid Full Name.";
    errors.jerseyName =
      state.jerseyName.trim().length > 0
        ? ""
        : "Please enter valid Jersey Name.";
    errors.jerseyNumber =
      state.jerseyNumber.length > 0 ? "" : "Please enter valid Jersey Number.";
    dispatch({
      type: AVATAR_ACTION_TYPE.handleError,
      payload: { errors: { ...errors } },
    });
  };

  const handleInputChangeF = (e) => {
    handleInputChange(e, dispatch, AVATAR_ACTION_TYPE);
  };

  const updateSelectedCategory = (newCategory) => {
    dispatch({
      type: AVATAR_ACTION_TYPE.switchAvatarCategory,
      payload: { selectedCategory: newCategory },
    });
  };

  const handleFinish = async () => {
    // change scene, after userlogs in
    dispatch({
      type: AVATAR_ACTION_TYPE.loading,
      payload: {
        isLoading: true,
      },
    });
    if (user) {
      //update name and avatar details
      try {
        let avatarConfiguration = {
          fullName: state.fullName,
          jerseyName: state.jerseyName,
          jerseyNumber: state.jerseyNumber,
          gender: state.gender,
          configuration: state.configuration,
        };
        await CallCloudFunction(CloudFunctionName.updateMetaUser, {
          avatarConfiguration,
        });
        if (window.playWalkThroughVideo) {
          window.playWalkThroughVideo();
        }
        if (isMobileOnly) {
          toggleFullScreen();
        }

        addGAAnalytics(AnalyticsEventName.FinishTheLook);
        dispatch({
          type: AVATAR_ACTION_TYPE.switchAvatar,
          payload: {
            activeComponent: AVATAR_MODAL_TYPE.CANVAS_LOADING,
            isLoading: false,
          },
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      //check for login, update name and avatar details if required or create a new one
      if (state.fullName) {
        let id = `${state.fullName
          .replace(/ /g, "")
          .toLowerCase()}_${new Date().getTime()}_${Math.floor(
          Math.random() * 1000
        )}@espnmeta.com`;
        let avatarConfiguration = {
          fullName: state.fullName,
          jerseyName: state.jerseyName,
          jerseyNumber: state.jerseyNumber,
          gender: state.gender,
          configuration: state.configuration,
        };
        try {
          await CallCloudFunction(CloudFunctionName.createMetaUser, {
            email: id,
            name: state.fullName,
            avatarConfiguration,
          });
        } catch (error) {
          console.log(error);
        }

        await loadUser(id, `${state.fullName}`);
        window.parent.playcanvasFirebase.forceLogin(id);
        if (isMobileOnly) {
          toggleFullScreen();
        }
        if (window.playWalkThroughVideo) {
          window.playWalkThroughVideo();
        }
        addGAAnalytics(AnalyticsEventName.FinishTheLook);
        dispatch({
          type: AVATAR_ACTION_TYPE.switchAvatar,
          payload: {
            activeComponent: AVATAR_MODAL_TYPE.CANVAS_LOADING,
            isLoading: false,
          },
        });
      }
    }
  };
  const handleBack = () => {
    dispatch({
      type: AVATAR_ACTION_TYPE.switchAvatar,
      payload: { activeScreen: CREATE_AVATAR.SCREEN_FIRST },
    });
  };
  return (
    <>
      {!isMobileOnly && (
        <div
          className={`${styles.canvasHolderDesktop} 
                ${props.hideAll ? styles.dnone : ""}
                `}
        >
          {props.children}
        </div>
      )}
      <div
        className={`${styles.parentContainer} ${
          props.hideAll
            ? styles.dnone
            : props.hideSideMenu && !isMobileOnly
            ? styles.dnone
            : ""
        } ${
          isMobileOnly &&
          state.activeComponent === AVATAR_MODAL_TYPE.CREATE_START &&
          styles.parentContainer2
        }`}
        style={{
          transform:
            isMobileOnly &&
            state.activeComponent === AVATAR_MODAL_TYPE.CREATE_START
              ? "scale(0.7)"
              : // `${window.innerWidth > window.innerHeight
                //   ? "scale(0.5)"
                //   : "scale(0.7)"
                // }`
                "scale(1)",
          height:
            state.activeComponent !== AVATAR_MODAL_TYPE.CREATE_START && "unset",
        }}
      >
        <div
          className={styles.container}
          style={{
            width: state.activeComponent === AVATAR_MODAL_TYPE.HOME && "unset",
          }}
        >
          {state.activeScreen === CREATE_AVATAR.SCREEN_SECOND ? (
            <img
              className={styles.backBtn}
              src={leftArrowIcon}
              alt=""
              onClick={handleBack}
            />
          ) : null}
          <h4>Choose your Avatar</h4>
          {state.activeScreen === CREATE_AVATAR.SCREEN_FIRST ? (
            <div className={styles["btn-container"]}>
              <label className={styles.switch}>
                <input
                  type="checkbox"
                  checked={state.gender}
                  onChange={(e) => {
                    dispatch({
                      type: AVATAR_ACTION_TYPE.loading,
                      payload: { gender: e.target.checked },
                    });
                  }}
                  name="color_mode"
                  id="color_mode"
                />
                <label
                  htmlFor="color_mode"
                  data-on="Female"
                  data-off="Male"
                  className={styles["switch-inner"]}
                ></label>
              </label>
            </div>
          ) : state.activeScreen === CREATE_AVATAR.SCREEN_SECOND ? (
            <div className={styles.userName}>{state.fullName}</div>
          ) : null}
          {isMobileOnly && (
            <div
              className={`${
                props.hideSideMenu
                  ? styles.canvasHolderFullScreen
                  : `${styles.canvasHolder}`
              }`}
            >
              {props.children}
            </div>
          )}
          {state.activeScreen === CREATE_AVATAR.SCREEN_FIRST ? (
            <AvatarForm
              state={state}
              handleSubmit={handleSubmit}
              handleInputChangeF={handleInputChangeF}
            />
          ) : state.activeScreen === CREATE_AVATAR.SCREEN_SECOND ? (
            <AvatarCustomization
              state={state}
              updateSelectedCategory={updateSelectedCategory}
              updateCategoryConfig={(payload) => {
                dispatch({
                  type: AVATAR_ACTION_TYPE.updateCategorySetting,
                  payload,
                });
              }}
            />
          ) : null}
          <div className={styles.bottomBtnTextFix}>
            {state.activeScreen === CREATE_AVATAR.SCREEN_FIRST ? (
              <button
                type={"submit"}
                className={`btn ${styles.btn}`}
                disabled={state.isLoading}
                onClick={handleSubmit}
              >
                {state.isLoading ? (
                  <img src={loaderImg} alt="loading..." height="100%" />
                ) : (
                  "Next"
                )}
              </button>
            ) : state.activeScreen === CREATE_AVATAR.SCREEN_SECOND ? (
              <button
                className={`btn ${styles.btn}`}
                disabled={state.isLoading}
                onClick={handleFinish}
              >
                {state.isLoading ? (
                  <img src={loaderImg} alt="loading..." height="100%" />
                ) : (
                  "Finish the look"
                )}
              </button>
            ) : null}
            {showRights.map(
              (comp, index) =>
                state.activeComponent === comp && (
                  <p className={styles.rights} key={index}>
                    &copy; ESPN Digital Media (India) Private Limited,
                    <br />
                    All Rights Reserved
                  </p>
                )
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateAvatarHOC;

const AvatarForm = ({ state, handleSubmit, handleInputChangeF }) => {
  const { errors } = state;
  const { user } = useContext(UserContext);
  return (
    <form onSubmit={handleSubmit}>
      {inputs.map((input) => (
        <div key={input.name} className={"input-group"}>
          <label htmlFor={input.name}>{input.label}</label>
          <input
            id={input.name}
            name={input.name}
            autoComplete="off"
            className={input.className}
            type={input.type}
            placeholder={input.placeholder}
            value={state[input.name]}
            required={input.required}
            disabled={input.disabled || (input.name === "fullName" && user)}
            readOnly={input.readOnly}
            onChange={
              input.preFun
                ? (e) => input.preFun(e, handleInputChangeF)
                : handleInputChangeF
            }
            maxLength={input.maxLength}
          />
          {errors[input.name] && (
            <div className="error">{errors[input.name]}</div>
          )}
        </div>
      ))}
    </form>
  );
};

const AvatarCustomization = ({
  state,
  updateSelectedCategory,
  updateCategoryConfig = () => {},
}) => {
  const { selectedCategory, gender } = state;
  return (
    <div className={styles.categoryContainer}>
      <div className={styles.avatarCategory}>
        {Object.keys(assetsType).map((key) => {
          const { img, title, enable, id } = assetsType[key];
          return (
            enable && (
              <div
                key={key}
                className={`${styles.item} ${
                  selectedCategory.id === id ? "activeItem" : ""
                }`}
                onClick={() => updateSelectedCategory(assetsType[key])}
              >
                <img src={img} alt={title} />
              </div>
            )
          );
        })}
      </div>
      <div
        className={`${styles.avatarCategoryItems} ${
          styles[selectedCategory.id]
        } ${selectedCategory.id === "tShirt" && styles.repeat3}`}
      >
        {Object.keys(selectedCategory.styles).map((key, index) => {
          const { img, title, id } = selectedCategory.styles[key];
          return (
            <div
              key={id}
              className={`${styles.item} ${
                selectedCategory.id == "specs"
                  ? state.configuration[
                      selectedCategory.id
                    ].optionIndex.indexOf(index) !== -1
                    ? styles.activeStyles
                    : ""
                  : state.configuration[selectedCategory.id].optionIndex ==
                    index
                  ? styles.activeStyles
                  : ""
              }`}
              onClick={() => {
                updateCategoryConfig({ type: "styles", val: index });
              }}
            >
              {selectedCategory.id == "hair" ? (
                <img src={img[gender ? "female" : "male"]} alt={title} />
              ) : (
                <img src={img} alt={title} />
              )}
            </div>
          );
        })}
      </div>
      <div
        className={`${styles.avatarCategoryItemsColor} ${
          selectedCategory.id !== "face" && styles.avatarCustomColor
        }`}
      >
        {Object.keys(selectedCategory.colors).map((key, index) => {
          const { color, title, id } = selectedCategory.colors[key];
          return (
            <div
              key={id}
              className={`${styles.item} ${
                state.configuration[selectedCategory.id].colorIndex == index
                  ? styles.activeStyles
                  : ""
              }`}
              style={{ backgroundColor: color }}
              onClick={() => {
                updateCategoryConfig({ type: "colors", val: index });
              }}
              title={title}
            />
          );
        })}
      </div>
    </div>
  );
};
