import React, { useContext, useEffect, useState } from "react";
import { NotificationContext } from "../../contexts/NotificationContextProvider";
import { UIContext } from "../../contexts/UIContextProvider";
import moment from "moment";
import CloseIcon from "../SVGIcons";
import { defaultNotificationIcon } from "../../constant/AssetsConstant";

export default function NotificationUserUI({ onPlatform }) {
  const { notificationState, updateLastSeenNotification } =
    useContext(NotificationContext);
  const { setActiveMenu } = useContext(UIContext);

  const [loading, setLoading] = useState(false);
  let notificationValues = Object.values(
    notificationState[onPlatform ? "platform_notifications" : "notifications"]
  ).reverse();
  const [lastNotification, setLastNotification] = useState(null);

  useEffect(() => {
    if (
      notificationState[
        onPlatform ? "platform_lastSeenNotification" : "lastSeenNotification"
      ]
    ) {
      setLastNotification(
        notificationState[
          onPlatform ? "platform_lastSeenNotification" : "lastSeenNotification"
        ]
      );
    }
  }, []);

  useEffect(() => {
    if (notificationValues.length > 0) {
      updateLastSeenNotification(notificationValues[0], onPlatform);
    }
  }, [
    notificationState[onPlatform ? "platform_notifications" : "notifications"],
  ]);

  return (
    <div className="communityBox__body">
      <header className="notyHeader">
        <div className={"notytitle"}>NOTIFICATIONS</div>
        <div className={"notycls"} onClick={() => setActiveMenu(null)}>
          <CloseIcon />
        </div>
      </header>
      <div className="poll-form-container">
        {loading && <div style={{ marginTop: "1rem" }}>Please wait...</div>}

        {notificationState[
          onPlatform ? "platform_notifications" : "notifications"
        ] &&
          (notificationValues.length > 0 ? (
            notificationValues.map((data, index) => (
              <React.Fragment key={data.id}>
                {data.publish ? (
                  <div className="poll-form poll-form-data">
                    <p
                      className="d-flex"
                      style={{
                        paddingTop: "10px",
                        marginBottom: "10px",
                        gap: "1rem",
                        fontWeight: `${
                          lastNotification
                            ? new Date(lastNotification.publishdat).getTime() <
                              new Date(data.publishdat).getTime()
                              ? "600"
                              : "400"
                            : "600"
                        }`,
                      }}
                    >
                      <img
                        style={{ width: "3rem" }}
                        src={"/logo192.png"}
                        alt=""
                      />
                      {data.message}
                    </p>
                    <div className="not-time-container">
                      {data.publishdat && (
                        <p style={{ marginTop: "5px" }}>
                          {" "}
                          {moment(new Date(data.publishdat)).format("LT")}{" "}
                        </p>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </React.Fragment>
            ))
          ) : (
            <p
              style={{
                paddingTop: "10px",
                marginBottom: "10px",
                fontWeight: 600,
              }}
            >
              {"No notifications yet."}
            </p>
          ))}
      </div>
    </div>
  );
}
