import uniqid from 'uniqid';
import firebase, { analytics, database } from '.';

import {
    isMobile,
    browserName
} from "react-device-detect";
import { RealtimeDatabasePaths } from '../constant/RealtimePath';

let realTimeDB = database;
let _savedSessionId = null;
let disconnectRef = null;

export const AnalyticsEventName = {
    "GoExplore": "GoExplore",
    "FinishTheLook": "FinishTheLook",
    "EnterCanvasBtn": "EnterCanvasBtn",
}

export const HotspotIdToAnalyticsName = {
    "Brand Room Screen_Left_2": "Brand_Room_BigPoster_left",
    "Brand Room Screen_Right_2": "Brand_Room_BigPoster_Right",
    "Brand Room Screen_Right_1": "Brand_Room_SmallPoster_Right",
    "Brand Room Screen_Left_1": "Brand_Room_SmallPoster_Left",
    "liveRoomScreen01": "Brand_Room_Screen",

    "lobby_rightScreen": "Lobby_SmallPoster_Right",
    "lobby_leftScreen": "Lobby_SmallPoster_Left",
    "Lobby_Big_Screen1": "Lobby_BigScrren_Video_Right",
    "Lobby_Big_Screen2": "Lobby_BigScrren_Video_Left",

    "game2": "Unscramble",
    "game1": "TrumpCards",
    "Play_Area_Screen_Right": "GameZone_SmallPoster_Right",
    "Play_Area_Screen_Left": "GameZone_SmallPoster_left",

    "Stats Room Screen_6": "StatsRoom_Poster_6",
    "Stats Room Screen_5": "StatsRoom_Poster_5",
    "Stats Room Screen_4": "StatsRoom_Poster_4",
    "Stats Room Screen_5": "StatsRoom_Poster_5",
    "Stats Room Screen_3": "StatsRoom_Poster_3",
    "Stats Room Screen_2": "StatsRoom_Poster_2",
    "Stats Room Screen_1": "StatsRoom_Poster_1",

    "link_askCricinfo": "AskCricInfo",

    "Live Show Screen_Right": "LiveRoom_SmallPoster_Right",
    "Live Show Screen_Left": "LiveRoom_SmallPoster_Left",
    "brandRoomScreen01": "LiveScreen",

    "LiveChat": "LiveChat",
    "one-on-oneChat": "AvatarChat",
    "carHotspot": "CarHotspot"
}

const getSessionId = () => {
    if (!_savedSessionId) {
        _savedSessionId = window.sessionStorage.getItem('sId');
        if (!_savedSessionId) {
            _savedSessionId = uniqid();
            window.sessionStorage.setItem('sId', _savedSessionId);
        }
    }
    return _savedSessionId
}

export const addGAAnalytics = (eventName, data = {}) => {
    analytics.logEvent(eventName, data)
}

export const addLoginAnalytics = async function (user) {
    return new Promise(async (resolve, reject) => {
        try {
            let _path = RealtimeDatabasePaths.Analytics + "/" + getSessionId();
            let _nodeRef = realTimeDB.ref(_path);
            let snapshot = await _nodeRef.get();
            let needToUpdate = false;
            let firstTime = false;
            if (!snapshot.exists()) {
                firstTime = true;
                needToUpdate = true;
            } else {
                let val = snapshot.val();
                if (!val.loginTime) {
                    needToUpdate = true;
                }
            }

            if (needToUpdate) {
                _nodeRef.update({
                    userid: user.uid,
                    name: user.displayName,
                    sessionId: getSessionId(),
                    loginTime: firebase.database.ServerValue.TIMESTAMP,
                    isMobile,
                    refresh: firstTime ? 0 : firebase.database.ServerValue.increment(1),
                    browserName,
                    email: user.email
                });
            } else {
                _nodeRef.update({
                    refresh: firebase.database.ServerValue.increment(1),
                });
            }

            disconnectRef = _nodeRef.onDisconnect()
            disconnectRef.update({
                logoutTime: firebase.database.ServerValue.TIMESTAMP,
            })
            resolve();
        } catch (err) {
            console.error(err);
            reject(err);
        }
    });
};

export const addHotspotAnalytics = (user, hotspotName) => {
    if (hotspotName && user) {
        let _path = RealtimeDatabasePaths.Analytics + "/" + getSessionId();
        console.log(_path)
        let _nodeRef = realTimeDB.ref(_path);
        _nodeRef.update({
            [hotspotName]: firebase.database.ServerValue.increment(1),
        });
    } else {
        console.error("user or hotspotName is missing, analytics failed");
    }
}


export const addLogoutAnalytics = (user) => {
    let _path = RealtimeDatabasePaths.Analytics + "/" + getSessionId();
    let _nodeRef = realTimeDB.ref(_path);
    _nodeRef.update({
        logoutTime: firebase.database.ServerValue.TIMESTAMP,
    });
    disconnectRef.cancel()
}