import NotificationAdminUI from "./NotificationAdminUI";
import NotificationUserUI from "./NotificationUserUI";
import "../../assets/css/PollContainer.css";

export default function SocketNotificationContainer(props) {
  const { id: eventId, isAdmin, onPlatform } = props;

  return (
    <>
      {isAdmin ? (
        <NotificationAdminUI eventId={eventId} onPlatform={onPlatform} />
      ) : (
        <NotificationUserUI eventId={eventId} onPlatform={onPlatform} />
      )}
    </>
  );
}
