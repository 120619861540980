const productionAPIServerLink = "https://metatestbackend.djvirtualevents.com";
export const LOGOUT_LINK =
  "https://idm.dev.zsservices.com/DJ/IdentityManager/app/Web/Logout.aspx";
const localAPIServerLink = "/api";

export const AuthUserCookieName = ".Oauth2.ESPN.Meta";
export let SERVER_DOMAIN = "localhost";
let serverURL = productionAPIServerLink;
if (window.location.href.includes("localhost")) {
  serverURL = localAPIServerLink;
  SERVER_DOMAIN = "localhost";
}

export const Endpoints = {
  root: serverURL + "/",
  logout: serverURL + "/logoutUser",
  verifyadmin: serverURL + "/verifyadmin",
  verifyuser: serverURL + "/verifyuser",
  vcroom: serverURL + "/vcroom",
  dailycoToken: serverURL + "/generate-videocall-Token",
  quizresponse: serverURL + "/quizresponse",
  signup: serverURL + "/signup",
  RoomPoints: {
    addRoomAdmin: serverURL + "/addRoomAdmin",
    updateRoomAdmin: serverURL + "/updateroomadmin",
    getRoomAdmin: serverURL + "/getRoomAdmin",
    setNotification: serverURL + "/setNotification",
    getNotification: serverURL + "/getNotification",
    vcAdminCheck: serverURL + "/vcAdminCheck",
  },

  YoutubePlaylist: "https://widget.tagembed.com/63746?view",

  espncricinfo: "https://www.espncricinfo.com/ask",
  espnGame: "https://www.espncricinfo.com/embed/game/trump",
  sampleImage: "/sampleAssets/sampleImage.jpg",
  canvasLink: "/canvas/index.html?profile=true&debug=true",
  avatarTest: "/TestProject_Avatar_ESPN/index.html",
  sampleVideo: "https://vimeo.com/339501018",
  Three60: "/Aframe360/index.html",
  game: "/wordScramble/index.html",
  introVideo: "https://vimeo.com/762140136/8efe87f3c0",
  introVideoMobile: "https://vimeo.com/762140420/64004dffc7",
  canvasLoading: "https://player.vimeo.com/video/761006433?h=d12a247fd4",
  chatBotIntroVideo: "https://player.vimeo.com/video/760552224?h=872dbb2150",
  chatBotQuestion1Video:
    "https://player.vimeo.com/video/760552224?h=872dbb2150",
  chatBotQuestion2Video:
    "https://player.vimeo.com/video/760552224?h=872dbb2150",
  chatBotQuestion3Video:
    "https://player.vimeo.com/video/760552224?h=872dbb2150",
};
