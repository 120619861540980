import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import App from "./App";
import RotateMessage from "./Components/RotateMessage";
import NotificationDashboard from "./Pages/NotificationDashboard";
const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route
          path="/notification-dashboard"
          element={<NotificationDashboard />}
        />

        <Route path="*" element={<Navigate to="/" replace={true} />} />
      </Routes>
      <RotateMessage />
    </BrowserRouter>
  );
};

export default Router;
