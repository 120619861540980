import { useEffect, useRef, useState } from "react";
import { isMobileOnly } from "react-device-detect";
import Vimeo from "react-vimeo-embed";
import {
  canvasLoading,
  loaderImg,
  walkThroughDesktopBG,
  walkThroughMobileBG,
} from "../../../../constant/AssetsConstant";
import { AVATAR_MODAL_TYPE } from "../../../../constant/TypeConstant";
import { CanvasLoading } from "../../../../Components";
import styles from "./index.module.scss";
import { Endpoints } from "../../../../constant/EndPoints";

const Intro = ({ switchComponent }) => {
  const vimeoRef = useRef();
  const introVideoRef = useRef();

  const [handleVideoState, setHandleVideoState] = useState({
    showTransitionVideo: false,
    loading: true,
    showSkip: false,
  });

  useEffect(() => {
    if (vimeoRef && vimeoRef.current) {
      //   vimeoRef.current.player.on(
      //     "timeupdate",
      //     ({ seconds, duration, percent }) => {
      //       if (seconds >= duration) {
      //         console.log("here");
      //         handleIntroSkip();
      //       }
      //     }
      //   );
      // vimeoRef.current.player.on("ended", (e) => {
      //   console.log("ended");
      //   handleIntroSkip();
      // });
    }
  }, [vimeoRef]);

  const handleContinue = () => {
    if (!introVideoRef || !introVideoRef.current) {
      // alert("Ref not assigned");
      return;
    }

    introVideoRef.current.play().then(() => {
      setHandleVideoState((prev) => ({
        ...prev,
        showSkip: true,
        loading: false,
        showTransitionVideo: true,
      }));
    });
    setHandleVideoState((prev) => ({ ...prev, loading: true }));
  };

  window.playWalkThroughVideo = handleContinue;

  const handleIntroReady = (event) => {
    introVideoRef.current = event;
    event
      .getPlayed()
      .then((e) => {
        console.log("getplayedd!!!", e);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleIntroSkip = (event) => {
    if (event) {
      event.preventDefault();
    }
    switchComponent(AVATAR_MODAL_TYPE.LOTTIE_TUT);
  };

  return (
    <>
      <div
        className={`${styles.IntroTransitionVideo} ${
          handleVideoState.loading
            ? styles.fadeInTransitionVideo
            : styles.fadeOutTransitionVideo
        }`}
      >
        {handleVideoState.loading && (
          <div
            className={styles.video}
            style={{
              width: isMobileOnly
                ? "calc(100vh * (9 / 16))"
                : "calc(100vh * (21 / 9))",
              backgroundImage: isMobileOnly
                ? `url(${walkThroughMobileBG})`
                : `url(${walkThroughDesktopBG})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: "100%",
            }}
          />

          // <div className="UILoader">
          //   <img src={loaderImg} alt="loading..." />
          // </div>
        )}
      </div>

      <div
        className={`${styles.IntroTransitionVideo} ${
          handleVideoState.showTransitionVideo
            ? styles.fadeInTransitionVideo
            : ""
        }`}
      >
        <Vimeo
          video={
            isMobileOnly ? Endpoints.introVideoMobile : Endpoints.introVideo
          }
          className={styles.video}
          style={{
            width: isMobileOnly
              ? "calc(100vh * (9 / 16))"
              : "calc(100vh * (21 / 9))",
          }}
          autopause={false}
          loop={false}
          volume={1}
          controls={false}
          ref={vimeoRef}
          onTimeUpdate={(e) => {
            if (e.percent >= 0.95) {
              handleIntroSkip();
            }
          }}
          onError={(e) => {
            setHandleVideoState((prev) => ({ ...prev, showSkip: true }));
            console.error("error", e);
          }}
          onReady={handleIntroReady}
        />
      </div>
      {handleVideoState.showSkip && (
        <div className={styles.skipContainer}>
          <button className={`btn ${styles.btn}`} onClick={handleIntroSkip}>
            Skip &gt;&gt;
          </button>
        </div>
      )}

      {/* {!handleVideoState.showTransitionVideo && (
        <CanvasLoading handleContinue={handleContinue} />
      )} */}
    </>
  );
};
export default Intro;
