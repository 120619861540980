import { AUDIO_CLIP_DATA } from "./AssetsConstant";

export const audioDataMale = [
  {
    id: "male_hi_there",
    audioFile: AUDIO_CLIP_DATA.male.HI_THERE,
    label: "Hi there!",
  },
  {
    id: "male_lets_chat",
    audioFile: AUDIO_CLIP_DATA.male.LETS_CHAT,
    label: "Let's chat!",
  },
  {
    id: "male_whats_up",
    audioFile: AUDIO_CLIP_DATA.male.WHATS_UP,
    label: "What's up!",
  },
  {
    id: "male_lets_play",
    audioFile: AUDIO_CLIP_DATA.male.LETS_PLAY,
    label: "Let's play",
  },
  {
    id: "male_follow_me",
    audioFile: AUDIO_CLIP_DATA.male.FOLLOW_ME,
    label: "Follow me",
  },
  { id: "male_cool", audioFile: AUDIO_CLIP_DATA.male.cool, label: "Cool" },
];
export const audioDataFemale = [
  {
    id: "female_hi_there",
    audioFile: AUDIO_CLIP_DATA.female.HI_THERE,
    label: "Hi there!",
  },
  {
    id: "female_lets_chat",
    audioFile: AUDIO_CLIP_DATA.female.LETS_CHAT,
    label: "Let's chat!",
  },
  {
    id: "female_whats_up",
    audioFile: AUDIO_CLIP_DATA.female.WHATS_UP,
    label: "What's up!",
  },
  {
    id: "female_lets_play",
    audioFile: AUDIO_CLIP_DATA.female.LETS_PLAY,
    label: "Let's play",
  },
  {
    id: "female_follow_me",
    audioFile: AUDIO_CLIP_DATA.female.FOLLOW_ME,
    label: "Follow me",
  },
  { id: "female_cool", audioFile: AUDIO_CLIP_DATA.male.cool, label: "Cool" },
];

export const GET_AUDIO_FROM_ID = {
  male_hi_there: AUDIO_CLIP_DATA.male.HI_THERE,

  male_lets_chat: AUDIO_CLIP_DATA.male.LETS_CHAT,

  male_whats_up: AUDIO_CLIP_DATA.male.WHATS_UP,

  male_lets_play: AUDIO_CLIP_DATA.male.LETS_PLAY,

  male_follow_me: AUDIO_CLIP_DATA.male.FOLLOW_ME,

  male_cool: AUDIO_CLIP_DATA.male.cool,

  female_hi_there: AUDIO_CLIP_DATA.female.HI_THERE,

  female_lets_chat: AUDIO_CLIP_DATA.female.LETS_CHAT,

  female_whats_up: AUDIO_CLIP_DATA.female.WHATS_UP,

  female_lets_play: AUDIO_CLIP_DATA.female.LETS_PLAY,

  female_follow_me: AUDIO_CLIP_DATA.female.FOLLOW_ME,

  female_cool: AUDIO_CLIP_DATA.male.cool,
};
