import { useContext } from "react";
import { clipIconImage } from "../../constant/AssetsConstant";
import { AvatarContext } from "../../contexts/AvatarContextProvider";
import Carousel from "react-multi-carousel";
import styles from "./index.module.scss";
import { useMemo } from "react";
import { audioDataFemale, audioDataMale } from "../../constant/MessageClipData";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 2,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

const MessageClip = ({ sendAudioFile }) => {
  const { state } = useContext(AvatarContext);

  const dataToRender = useMemo(
    () => (state.gender ? audioDataFemale : audioDataMale),
    [state.gender]
  );

  return (
    <div className={styles.container}>
      <Carousel
        swipeable
        draggable
        infinite
        containerClass={styles.slider}
        itemClass={styles.label}
        responsive={responsive}
      >
        {dataToRender.map((data, index) => (
          <div
            onClick={() => sendAudioFile(data)}
            key={index}
            className={styles.customLabel}
          >
            <img src={clipIconImage} alt="" />
            <div>{data.label}</div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default MessageClip;
