import { useEffect } from "react";
import { useContext, useState } from "react";
import { MAP_DATA } from "../../constant/AssetsConstant";
import { UIContext } from "../../contexts/UIContextProvider";
import CloseIcon from "../SVGIcons";
import styles from "./index.module.scss";

export const MAP_LOCATION_ID = {
  1: "lobby",
  2: "gameRoom",
  3: "statsRoom",
  4: "brandRoom",
  5: "liveShow",
};

const data = [
  {
    enable: true,
    id: "lobby",
    canvasId: "lobby",
    title: "Lobby",
    description:
      "Your gateway to everything ESPNcricinfo, from cricket stats and facts to latest scores, news and lots more",
    ctaText: "Visit Now",
    link: "",
    img: MAP_DATA.lobby,
    imgWithUser: MAP_DATA.lobbyWithUser,
  },
  {
    enable: true,
    id: "gameRoom",
    canvasId: "gameRoom",
    title: "Game Room",
    description:
      "Relive your childhood and play trump card or test your word skills with Cricket scrabble. Challenge yourself and try from our two unique yet simple and addictive games",
    ctaText: "Visit Now",
    link: "",
    img: MAP_DATA.gameRoom,
    imgWithUser: MAP_DATA.gameRoomWithUser,
  },
  {
    enable: true,
    id: "statsRoom",
    canvasId: "statsRoom",

    title: "Stats Room",
    description:
      "Check out the latest records and numbers related to players and teams. In case you want to know more, you can “always askcricinfo”",
    ctaText: "Visit Now",
    link: "",
    img: MAP_DATA.statsRoom,
    imgWithUser: MAP_DATA.statsRoomWithUser,
  },
  {
    enable: true,
    id: "brandRoom",
    canvasId: "brandRoom",

    title: "Brand Room",
    description:
      "Check out “The Advanced Grand Vitara - Rule Every Road” in all its glory",
    ctaText: "Visit Now",
    link: "",
    img: MAP_DATA.brandRoom,
    imgWithUser: MAP_DATA.brandRoomWithUser,
  },
  {
    enable: true,
    id: "liveShow",
    canvasId: "liveRoom",
    title: "Live Studio",
    description:
      "Watch ESPNcricinfo Game around the game coverage through live show and latest video content with our in house Anchor Raunak Kapoor",
    ctaText: "Visit Now",
    link: "",
    img: MAP_DATA.liveShow,
    imgWithUser: MAP_DATA.liveShowWithUser,
  },
];

const Map = () => {
  const { backstageAdminData, setActiveMenu, toggleMenuOpen, mapLocation } =
    useContext(UIContext);
  const [activeScene, setActiveScene] = useState(data[0]);
  const [userScene, setUserScene] = useState(data[0]);

  useEffect(() => {
    if (mapLocation) {
      setActiveScene(data[mapLocation - 1]);
      setUserScene(data[mapLocation - 1]);
    }
  }, [mapLocation]);

  const toggleActiveScene = (scene) => {
    setActiveScene(scene);
  };

  const handleClick = () => {
    console.log("active scene", activeScene);
    if (window.parent.canvasTest) {
      if (window.parent.canvasTest.teleport) {
        window.parent.canvasTest.teleport(activeScene.canvasId);
        setTimeout(() => {
          toggleMenuOpen(null);
          setActiveMenu(null);
        }, 200);
      }
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>MAP</div>
        <div
          className={styles.closeBtn}
          onClick={() => {
            toggleMenuOpen(null);
            setActiveMenu(null);
          }}
        >
          <CloseIcon />
        </div>
      </div>
      <div className={styles.detailsContainer}>
        <div className={styles.left}>
          <img
            className={styles.tickerImg}
            src={userScene.imgWithUser}
            alt=""
          />
          <img src={activeScene.img} alt="" />
        </div>
        <div className={styles.right}>
          <div className={styles.scrollbar}>
            {data.map((item) => {
              const { id, enable, title } = item;
              return (
                enable && (
                  <div
                    key={id}
                    className={`${styles.item} ${activeScene.id === id ? styles.active : ""
                      }`}
                    onClick={() => toggleActiveScene(item)}
                  >
                    {title}
                  </div>
                )
              );
            })}
          </div>
          <div className={styles.details}>
            <div className={styles.live}>
              <h4 className={styles.title}>{activeScene.title}</h4>
              {activeScene.id === "liveShow" &&
                backstageAdminData?.liveSpace?.id === "liveShow" &&
                backstageAdminData?.liveSpace?.status && (
                  <div className={styles.liveContainer}>
                    <div className={styles.liveIndicator} />
                    <div>LIVE NOW</div>
                  </div>
                )}
            </div>
            <p className={styles.description}>{activeScene.description}</p>
            <button onClick={handleClick} className={"btn"}>
              {activeScene.ctaText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Map;
