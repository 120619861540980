import { useContext } from "react";
import { useState } from "react";
import { isMobileOnly } from "react-device-detect";
import { cricInfoImg } from "../../../../constant/AssetsConstant";
import { AVATAR_MODAL_TYPE } from "../../../../constant/TypeConstant";
import { AvatarContext } from "../../../../contexts/AvatarContextProvider";
import { UIContext } from "../../../../contexts/UIContextProvider";
import { addGAAnalytics, AnalyticsEventName } from "../../../../Firebase/analytics";

import styles from "./index.module.scss";

/* View in fullscreen */
const openFullscreen = () => {
  /* Get the documentElement (<html>) to display the page in fullscreen */
  var elem = document.documentElement;

  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.webkitRequestFullscreen) {
    /* Safari */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    /* IE11 */
    elem.msRequestFullscreen();
  }
};
const StartModal = ({ switchComponent }) => {
  const [isPrivacyAccepted, setIsPrivacyAccepted] = useState(false);
  const { loadingPercentage } = useContext(UIContext);
  const { playBGAudio } = useContext(AvatarContext);

  const handleBtnClick = () => {
    playBGAudio();
    switchComponent(AVATAR_MODAL_TYPE.CREATE_START);
    addGAAnalytics(AnalyticsEventName.GoExplore);
    if (isMobileOnly) {
      openFullscreen();
    }
  };

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.imgContainer}`}>
        <img src={cricInfoImg} alt="" />
      </div>
      <div className={`${styles.detailsContainer}`}>
        {/* <h1>Hello!</h1> */}
        <h4>Welcome to Cricinfoverse!</h4>
        <p style={{ marginBottom: "1rem" }}>
          A place for cricket fanatics like you who love everything
          ESPNcricinfo.
        </p>
        <p>So what are you waiting for ?</p>

        <label className={`customCheckBox ${styles.privacyContainer}`}>
          I accept the{" "}
          <a
            href="https://www.espncricinfo.com/ci/content/page/1276987.html"
            target="_blank"
            rel="noreferrer"
          >
            Terms &amp; Conditions
          </a>
          <input
            type="checkbox"
            checked={isPrivacyAccepted}
            onChange={(e) => setIsPrivacyAccepted(e.target.checked)}
          />
          <span className="checkmark"></span>
        </label>

        <button
          disabled={!(isPrivacyAccepted && loadingPercentage == 100)}
          onClick={handleBtnClick}
          className="btn"
        >
          <span>{loadingPercentage < 100 ? "Loading" : "Go Explore"}</span>
          <span
            className={styles.loader}
            style={{
              height: `${loadingPercentage}%`,
              transition: "0.5s",
              opacity: loadingPercentage == 100 ? 0 : 1,
            }}
          ></span>
        </button>
      </div>
    </div>
  );
};

export default StartModal;
