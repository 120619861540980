import React, { createContext, useState, useEffect } from "react";

import {
  addGAAnalytics,
  addHotspotAnalytics,
  addLoginAnalytics,
  addLogoutAnalytics,
} from "../Firebase/analytics";
import { UpdateUserTable, user_ActiveStatus } from "../Firebase/chatManager";

import { setCookie } from "../utils";
import {
  analytics,
  auth,
  checkUserRoleFromRDB,
  getAvatarDetails,
  login,
} from "../Firebase";
import { updateName } from "../Firebase/auth";
import { AuthUserCookieName } from "../constant/EndPoints";

export const UserContext = createContext();

export default function UserContextProvider(props) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // console.log(`-> window.parent.sa_mode = "expo"`)
    window.parent.loginUser = (email, password) => {
      login(email, password);
    };
    // userCheck()

    auth.onAuthStateChanged(async (user) => {
      if (user) {
        console.log(user.email, user.displayName);
        let __user = {
          displayName: user.displayName,
          email: user.email,
          role: "user",
          uid: user.email,
          userid: user.email,
          f_uid: user.uid,
        };

        if (!user.displayName)
          updateName(user, user.email.split("@")[0]);

        analytics.setUserId(user.uid);

        const url = new URLSearchParams(window.location.search);
        let _admin = url.get("notification");

        //get user role
        let platfromRole = await checkUserRoleFromRDB(user.email);
        if (window.parent.makeAdmin || _admin) {
          platfromRole = "admin";
        }
        __user = {
          ...__user,
          platfromRole,
        };
        UpdateUserTable(user);
        user_ActiveStatus(user);

        localStorage.setItem("userAuth", JSON.stringify(__user));

        //set user
        let idToken = await user.getIdToken(true);
        localStorage.setItem("userIdToken", idToken);
        window.userToken = idToken;
        // console.log("idToken");
        // console.log(idToken);
        setCookie(AuthUserCookieName, idToken);

        getAvatarDetails(user.uid).then((data) => {
          if (data) {
            window.parent.userAvatarData = data;
          }
        });

        setUser({ ...__user, isChecked: true });

        addLoginAnalytics(user).then(() => {
          console.log("analytics started");
        });

        window.parent.platformAnalytics = {
          addHotspotAnalytics: (hotspotName) =>
            addHotspotAnalytics(user, hotspotName),
          addLogoutAnalytics: () => addLogoutAnalytics(user),
          addGAAnalytics: (eventName, data) => addGAAnalytics(eventName, data)
        };
      } else {
        console.log("user not logged in");
        localStorage.removeItem("userAuth");
        localStorage.clear();
        setUser(null);
      }
    });
  }, []);

  return (
    <UserContext.Provider value={{ user }}>
      {props.children}
    </UserContext.Provider>
  );
}
