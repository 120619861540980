import React, {
  createContext,
  useEffect,
  useReducer,
  useRef,
  useContext,
} from "react";
import { PLATFORM_ROLES } from "../constant/PlatformRoles";
import { NotificationFirebaseManager } from "../Firebase/NotificationFirebase";
import { UserContext } from "./UserContextProvider";

export const NotificationContext = createContext();

const initalRoomState = {
  currentRoomId: null,
  isNotificationAdmin: false,
  notifications: {},
  lastSeenNotification: null,
  initialized: false,
};

const initalPlatformState = {
  platform_currentRoomId: null,
  platform_isNotificationAdmin: false,
  platform_notifications: {},
  platform_lastSeenNotification: null,
  platform_initialized: false,
};

const initalState = {
  ...initalRoomState,
  ...initalPlatformState,
  flashNotification: null,
};
const NOTIFICATION_ACTION_TYPE = {
  joinRoom: "joinRoom",
  leaveRoom: "leaveRoom",
  initalize: "initalize",
  updateNotification: "updateNotification",
  addBulkNotifications: "addBulkNotifications",
  removeNotifications: "removeNotifications",
  removeFlashNotifications: "removeFlashNotifications",
  updateLastSeenNotification: "updateLastSeenNotification",
  reset: "reset",
};

function reducer(state, { type, payload }) {
  //  console.log({ type, payload })
  const { isPlatformNotification } = payload;
  switch (type) {
    case NOTIFICATION_ACTION_TYPE.joinRoom:
      return { ...state, currentRoomId: payload.roomid };
    case NOTIFICATION_ACTION_TYPE.leaveRoom:
      if (state.currentRoomId === payload.roomid) {
        return {
          ...state,
          ...initalRoomState,
        };
      } else {
        return state;
      }
    case NOTIFICATION_ACTION_TYPE.initalize:
      return {
        ...state,
        [isPlatformNotification
          ? "platform_isNotificationAdmin"
          : "isPollAdmin"]: payload.isRoomAdmin,
        [isPlatformNotification ? "platform_initialized" : "initialized"]:
          payload.initialized,
      };
    case NOTIFICATION_ACTION_TYPE.addBulkNotifications:
      let newState = null;
      if (isPlatformNotification) {
        newState = {
          ...state,
          ["platform_notifications"]: {
            ...state.platform_notifications,
            ...payload.notifications,
          },
        };
      } else {
        newState = {
          ...state,
          notifications: {
            ...state.notifications,
            ...payload.notifications,
          },
        };
      }
      if (payload.markAsLastNotification) {
        newState = {
          ...newState,
          flashNotification: payload.flashNotification,
        };
      }
      if (payload.removeFlashNotification && state.flashNotification) {
        if (state.flashNotification.id === payload.flashNotification.id) {
          newState = {
            ...newState,
            flashNotification: null,
          };
        }
      }
      return newState;
    case NOTIFICATION_ACTION_TYPE.removeNotifications: {
      let notificationsid = payload.notificationsid;
      let notificationListRef = isPlatformNotification
        ? state.platform_notifications
        : state.notifications;
      if (notificationListRef.hasOwnProperty(notificationsid)) {
        let notifications = { ...notificationListRef };
        delete notifications[notificationsid];
        let newState = {
          ...state,
          [isPlatformNotification ? "platform_notifications" : "notifications"]:
            notifications,
        };
        if (payload.removeLastNotification && state.flashNotification) {
          if (state.flashNotification.id === notificationsid) {
            newState = {
              ...newState,
              flashNotification: null,
            };
          }
        }
        return newState;
      } else {
        return state;
      }
    }
    case NOTIFICATION_ACTION_TYPE.removeFlashNotifications: {
      return {
        ...state,
        flashNotification: null,
      };
    }
    case NOTIFICATION_ACTION_TYPE.updateLastSeenNotification: {
      return {
        ...state,
        [isPlatformNotification
          ? "platform_lastSeenNotification"
          : "lastSeenNotification"]: payload.data,
      };
    }
    case NOTIFICATION_ACTION_TYPE.reset: {
      return initalState;
    }
    default:
      throw new Error();
  }
}
const getEventNameForOperation = (roomid, operation) => {
  return `${roomid}:${operation}`;
};

export default function NotificationContextProvider(props) {
  const socketRef = useRef(null);
  const lastNotificationTimerRef = useRef(null);
  const { user } = useContext(UserContext);
  const [state, dispatch] = useReducer(reducer, initalState);

  const isListenerAttached = useRef(false);
  const attachNotificationListeners = (
    roomid,
    isPlatformNotification = false
  ) => {
    if (!isPlatformNotification) {
      if (isListenerAttached.current) {
        detachNotificationListener();
      }
      isListenerAttached.current = true;
      //  console.log("attach Notification listener")
    }
    let socket = socketRef.current;

    const handleUpdateNotification = (data) => {
      //  console.log("----------------------handleAddNotification")
      //  console.log(data)

      if (data) {
        // console.log("fdasfsa", data);
        let processData = {};
        processData[data.id] = data;
        // processData[data.roomid] = data;
        dispatch({
          type: NOTIFICATION_ACTION_TYPE.addBulkNotifications,
          payload: {
            notifications: processData,
            isPlatformNotification,
            markAsLastNotification: data.ispublished,
            flashNotification: data,
            removeFlashNotification: !data.ispublished,
          },
        });
      }
    };
    const handleTogglePublishNotificationAsClient = (data) => {
      //  console.log("----------------------handleTogglePublishNotificationAsClient")
      if (data) {
        if (data.ispublished) {
          dispatch({
            type: NOTIFICATION_ACTION_TYPE.addBulkNotifications,
            payload: {
              notifications: { [data.id]: data },
              isPlatformNotification,
              markAsLastNotification: true,
              flashNotification: data,
            },
          });
        } else {
          dispatch({
            type: NOTIFICATION_ACTION_TYPE.removeNotifications,
            payload: {
              notificationsid: data.id,
              isPlatformNotification,
              removeLastNotification: true,
            },
          });
        }
      }
    };
    const handleDeleteNotification = (data) => {
      //  console.log("----------------------handleDeleteNotification")
      if (data) {
        dispatch({
          type: NOTIFICATION_ACTION_TYPE.removeNotifications,
          payload: { notificationsid: data.id, isPlatformNotification },
        });
      }
    };

    const handleGetNotifications = (data) => {
      //  console.log("----------------------handleGetNotifications")
      if (data) {
        let processData = {};
        processData[data.id] = data;

        // console.log("gete notificaiton", data);
        // data.forEach((not) => {
        //   processData[not.id] = not;
        // });
        dispatch({
          type: NOTIFICATION_ACTION_TYPE.addBulkNotifications,
          payload: { notifications: processData, isPlatformNotification },
        });
      }
    };

    NotificationFirebaseManager.addListener(
      "platform-notification",
      platformLisRef.current,
      {
        onCreate: (data) => {
          // console.log("childget");
          // console.log("cahild get", data.id);
          let processData = {};
          processData[data.id] = data;
          // console.log("processData get", processData);
          handleGetNotifications(data);
          // handleVideoReady(data);
        },
        onUpdate: (data) => {
          console.log("onupdate", data);
          handleUpdateNotification(data);
        },
        onDelete: handleDeleteNotification,
      }
    );
  };

  const detachNotificationListener = (roomid) => {
    if (isListenerAttached.current) {
      isListenerAttached.current = false;
    }
    // dispatch({ type: NOTIFICATION_ACTION_TYPE.reset })
    //  console.log("detach Notification listener")
  };
  const platformLisRef = useRef();
  useEffect(() => {
    const handleVideoCallRoomJoin = ({ roomid, vcsessionid, permissions }) => {
      attachNotificationListeners(roomid);
      dispatch({
        type: NOTIFICATION_ACTION_TYPE.joinRoom,
        payload: { roomid },
      });
    };
    const handleVideoCallRoomLeft = ({ roomid }) => {
      dispatch({
        type: NOTIFICATION_ACTION_TYPE.leaveRoom,
        payload: { roomid },
      });
    };

    window.testNot_Toggle = togglePublishNotification;
  }, []);

  useEffect(() => {
    if (user) {
      dispatch({
        type: NOTIFICATION_ACTION_TYPE.initalize,
        payload: {
          isRoomAdmin: user.platfromRole === PLATFORM_ROLES.admin,
          initialized: true,
          isPlatformNotification: true,
        },
      });
      attachNotificationListeners("platform-notification", true);
    }
  }, [user]);

  useEffect(() => {
    if (state.currentRoomId) {
      // attachPollListeners(state.currentRoomId)
    } else {
      detachNotificationListener();
    }
    return () => {
      detachNotificationListener();
    };
  }, [state.currentRoomId]);

  useEffect(() => {
    document.body.style.backgroundColor = "black";
    if (lastNotificationTimerRef.current) {
      clearTimeout(lastNotificationTimerRef.current);
    }
    if (state.flashNotification) {
      lastNotificationTimerRef.current = setTimeout(() => {
        console.log(state.flashNotification, "clean");
        dispatch({
          type: NOTIFICATION_ACTION_TYPE.removeFlashNotifications,
          payload: { isPlatformNotification: false },
        });
      }, 10 * 1000);
    }
    return () => {
      clearTimeout(lastNotificationTimerRef.current);
    };
  }, [state.flashNotification]);

  const addNotification = ({ roomid, messageid, message, publish }) => {
    // console.log("-----------add");
    NotificationFirebaseManager.addNotification({
      roomid,
      id: messageid,
      message,
      publish,
    });
  };

  const updateNotification = ({ roomid, messageid, message }) => {
    //  console.log('-----------updateNotification')

    NotificationFirebaseManager.updateNotification({
      roomid,
      id: messageid,
      message,
    });
  };

  const togglePublishNotification = ({ roomid, messageid, publish }) => {
    console.log(
      "-----------togglePublishNotification",
      roomid,
      messageid,
      publish
    );

    NotificationFirebaseManager.togglePublishNotification({
      roomid,
      id: messageid,
      publish,
    });
  };

  const deleteNotification = ({ messageid, roomid }) => {
    console.log("-----------deleteNotification");
    NotificationFirebaseManager.deleteNotification({
      roomid,
      id: messageid,
    });
  };

  // deleteNotification({
  //   roomid: "platform-notification",
  //   messageid: "29adb45a-3b54-4a74-a194-76ec002d4ee7",
  //   // message: "hello this is updated message",
  // });

  const updateLastSeenNotification = (data, isPlatformNotification) => {
    dispatch({
      type: NOTIFICATION_ACTION_TYPE.updateLastSeenNotification,
      payload: { data, isPlatformNotification },
    });
  };

  return (
    <NotificationContext.Provider
      value={{
        notificationState: state,
        dispatch,
        addNotification,
        updateNotification,
        togglePublishNotification,
        deleteNotification,
        updateLastSeenNotification,
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
}
