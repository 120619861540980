import { useContext } from "react";
import { isMobile } from "react-device-detect";
import Lottie from "react-lottie-player";
import {
  bottomBorderImg,
  LOTTIE_TUT_DATA,
} from "../../constant/AssetsConstant";
import { AVATAR_MODAL_TYPE } from "../../constant/TypeConstant";
import { UIContext } from "../../contexts/UIContextProvider";
import { addGAAnalytics, AnalyticsEventName } from "../../Firebase/analytics";
import styles from "./index.module.scss";

const lottiesToShow = [
  {
    id: "touch",
    title: "Touch and drag to pan",
    enable: true,
    lottieData: LOTTIE_TUT_DATA.touch,
  },
  {
    id: "joystick",
    title: "Use the joystick to move forward",
    enable: true,
    lottieData: LOTTIE_TUT_DATA.joystick,
  },
  {
    id: "keyboard",
    title: "Use WASD to move forward and backward",
    enable: !isMobile,
    lottieData: LOTTIE_TUT_DATA.keyboard,
  },
];

const LottieTut = ({ switchComponent }) => {
  const { environmentLoaded, toggleEnvLoaded } = useContext(UIContext);
  return (
    <div className={styles.container}>
      <h4>NAVIGATION CONTROLS</h4>
      <img src={bottomBorderImg} alt="" />
      <div className={styles.lottieContainer}>
        {lottiesToShow.map(
          ({ id, title, enable, lottieData }, index) =>
            enable && (
              <div key={id} className={styles.item}>
                <Lottie
                  className={styles.lottie}
                  loop={true}
                  play={true}
                  speed={1}
                  goTo={0}
                  direction={1}
                  // segments={segmentsEnabled && segments}
                  animationData={lottieData}
                  // onComplete={handleComplete}
                  // onLoopComplete={handleLoopComplete}
                  // onEnterFrame={handleEnterFrame}
                  // onSegmentStart={() => handleSegmentStart("segmentStart")}
                  // onLoad={handleLoad}
                />
                <p className={styles.title}>{title}</p>
              </div>
            )
        )}
      </div>
      <button
        disabled={!environmentLoaded}
        type={"submit"}
        className={`btn ${styles.btn}`}
        style={{ width: "unset" }}
        onClick={() => {
          setTimeout(() => {
            window.parent.canvasTest.openJoystick();
          }, 1000);
          switchComponent(AVATAR_MODAL_TYPE.HOME);
          addGAAnalytics(AnalyticsEventName.EnterCanvasBtn);
        }}
      >
        Continue
      </button>
    </div>
  );
};

export default LottieTut;
