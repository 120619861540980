import { useContext, useRef } from "react";
import { createContext, useEffect, useReducer } from "react";
import {
  assetsType,
  AVATAR_MODAL_TYPE,
  CREATE_AVATAR,
} from "../constant/TypeConstant";
import { getAvatarDetails } from "../Firebase";
import { MediaModalContext } from "./MediaModalContextProvider";
import { UserContext } from "./UserContextProvider";
export const AvatarContext = createContext();

const defaultErr = {
  fullName: "",
  jerseyName: "",
  jerseyNumber: "",
};
const initialState = {
  isLoading: false,
  activeComponent: AVATAR_MODAL_TYPE.START_EXPERIENCE,
  activeScreen: CREATE_AVATAR.SCREEN_FIRST,
  selectedCategory: assetsType["hair"],

  fullName: "",
  jerseyName: "",
  jerseyNumber: "",
  gender: false, // true for female, false for male

  isAudioPlaying: true,

  configuration: {
    hair: {
      optionIndex: 0,
      colorIndex: 0,
    },
    face: {
      optionIndex: 0,
      colorIndex: 0,
    },
    tShirt: {
      optionIndex: 0,
      colorIndex: 0,
    },
    specs: {
      optionIndex: [],
      colorIndex: 0,
    },
  },

  errors: {
    ...defaultErr,
  },
};

export const AVATAR_ACTION_TYPE = {
  handleInputChange: "handleInputChange",
  handleInitialInput: "handleInitialInput",
  switchAvatar: "switchAvatar",
  switchAvatarCategory: "switchAvatarCategory",
  toggleBGAudio: "toggleBGAudio",
  updateCategorySetting: "updateCategorySetting",
  handleError: "handleError",
  loading: "loading",
  syncWithUserData: "syncWithUserData",
};

const reducer = (state, action) => {
  let { type, payload } = action;

  switch (type) {
    case AVATAR_ACTION_TYPE.handleInputChange:
      return { ...state, ...payload, [payload.name]: payload.value };
    case AVATAR_ACTION_TYPE.loading:
    case AVATAR_ACTION_TYPE.switchAvatar:
    case AVATAR_ACTION_TYPE.switchAvatarCategory:
      return { ...state, ...payload };
    case AVATAR_ACTION_TYPE.toggleBGAudio:
      return {
        ...state,
        isAudioPlaying: !state.isAudioPlaying,
      };
    case AVATAR_ACTION_TYPE.updateCategorySetting:
      let prevConfiguration = { ...state.configuration };
      const optionToTypeMap = {
        styles: "optionIndex",
        colors: "colorIndex",
      };
      if (state.selectedCategory.id !== "specs") {
        prevConfiguration[state.selectedCategory.id] = {
          ...prevConfiguration[state.selectedCategory.id],
          [optionToTypeMap[payload.type]]: payload.val,
        };
      } else {
        let _index = prevConfiguration[
          state.selectedCategory.id
        ].optionIndex.indexOf(payload.val);
        if (_index == -1) {
          prevConfiguration[state.selectedCategory.id].optionIndex.push(
            payload.val
          );
        } else {
          prevConfiguration[state.selectedCategory.id].optionIndex.splice(
            _index,
            1
          );
        }

        prevConfiguration[state.selectedCategory.id] = {
          ...prevConfiguration[state.selectedCategory.id],
        };
      }
      return {
        ...state,
        configuration: prevConfiguration,
      };

    case AVATAR_ACTION_TYPE.syncWithUserData:
      return {
        ...state,
        ...payload,
      };
    default:
      return { ...state };
  }
};

export default function AvatarContextProvider(props) {
  const { modalDetails, mediaModalStatus } = useContext(MediaModalContext);
  const { user } = useContext(UserContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const bgAudioRef = useRef();

  useEffect(() => {
    if (mediaModalStatus) {
      stopBGAudio();
    } else {
      playBGAudio();
    }
  }, [mediaModalStatus]);

  useEffect(() => {
    if (state.activeComponent === AVATAR_MODAL_TYPE.CANVAS_LOADING) {
      stopBGAudio();
    } else {
      playBGAudio();
    }
  }, [state.activeComponent]);

  useEffect(() => {
    if (!state.isAudioPlaying) {
      stopBGAudio();
    } else {
      playBGAudio();
    }
    window.addEventListener("focus", playBGAudio);
    // window.addEventListener("blur", stopBGAudio);
    return () => {
      window.removeEventListener("focus", playBGAudio);
      // window.removeEventListener("blur", stopBGAudio);
    };
  }, [state.isAudioPlaying]);

  const syncWithUserData = async (user) => {
    try {
      let __data = await getAvatarDetails(user.f_uid);
      console.log(__data);
      if (__data.configuration.specs) {
        if (!__data.configuration.specs.optionIndex) {
          console.log("putting empty array");
          __data.configuration.specs.optionIndex = [];
        }
      }
      dispatch({
        type: AVATAR_ACTION_TYPE.syncWithUserData,
        payload: __data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (user) {
      if (!state.jerseyName) {
        syncWithUserData(user);
      }
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      if (user.displayName && !state.fullName) {
        dispatch({
          type: AVATAR_ACTION_TYPE.handleInputChange,
          payload: { name: "fullName", value: user.displayName },
        });
      }
    }
  }, [user, state]);

  const switchActiveComponent = (type) => {
    if (type === AVATAR_MODAL_TYPE.CANVAS_LOADING) {
      stopBGAudio();
    } else {
      playBGAudio();
    }
    dispatch({
      type: AVATAR_ACTION_TYPE.switchAvatar,
      payload: { activeComponent: type },
    });
  };

  useEffect(() => {
    if (window.parent.canvasTest) {
      if (state.activeComponent == AVATAR_MODAL_TYPE.LOTTIE_TUT) {
        if (window.parent.canvasTest.testingTurnOff)
          window.parent.canvasTest.testingTurnOff();
      } else if (state.activeComponent == AVATAR_MODAL_TYPE.CREATE_START) {
        let characterCustomization =
          window.parent.canvasTest.characterCustomization;
        characterCustomization.updateCharacterConfiguration({
          gender: state.gender ? 1 : 0,
          hairStyle: {
            selection: state.configuration.hair.optionIndex,
            color: state.configuration.hair.colorIndex,
          },
          skin: state.configuration.face.colorIndex,
          cloth: state.configuration.tShirt.optionIndex,
          accessorie: state.configuration.specs?.optionIndex,
        });
        characterCustomization.updateNameAndNumber(
          !state.jerseyName ? "Name" : state.jerseyName,
          !state.jerseyNumber ? "00" : state.jerseyNumber
        );
      }
    }
    if (state.activeComponent == AVATAR_MODAL_TYPE.HOME) {
      playBGAudio();
    }
  }, [state.activeComponent]);

  useEffect(() => {
    if (state.activeScreen == CREATE_AVATAR.SCREEN_SECOND) {
      if (window.parent.canvasTest) {
        if (window.parent.canvasTest.animateCameraToFront) {
          window.parent.canvasTest.animateCameraToFront();
        } else if (window.parent.canvasTest.animateCamera)
          window.parent.canvasTest.animateCamera(180, 0, 0);

        if (window.parent.canvasTest.freezeCameraMovement)
          window.parent.canvasTest.freezeCameraMovement(false);
      }
    } else if (state.activeScreen == CREATE_AVATAR.SCREEN_FIRST) {
      if (window.parent.canvasTest) {
        if (window.parent.canvasTest.animateCameraToBack) {
          window.parent.canvasTest.animateCameraToBack();
        }
      }
    }
  }, [state.activeScreen]);

  useEffect(() => {
    // console.log("gender Updated");
    if (window.parent.canvasTest) {
      let characterCustomization =
        window.parent.canvasTest.characterCustomization;
      characterCustomization.changeGender(
        state.gender
          ? 1 //female
          : 0 // male
      );
      characterCustomization.turnAvatarOn();
      characterCustomization.updateCharacterConfiguration({
        gender: state.gender ? 1 : 0,
        hairStyle: {
          selection: state.configuration.hair.optionIndex,
          color: state.configuration.hair.colorIndex,
        },
        skin: state.configuration.face.colorIndex,
        cloth: state.configuration.tShirt.optionIndex,
        accessorie: state.configuration.specs.optionIndex,
      });
    }
  }, [state.gender]);

  useEffect(() => {
    if (window.parent.canvasTest) {
      // console.log("jerseyName Updated");
      let characterCustomization =
        window.parent.canvasTest.characterCustomization;
      characterCustomization.updateNameAndNumber(
        !state.jerseyName ? "Name" : state.jerseyName,
        !state.jerseyNumber ? "00" : state.jerseyNumber
      );
    }
  }, [state.jerseyName, state.jerseyNumber]);

  useEffect(() => {
    if (window.parent.canvasTest) {
      // console.log("hair Updated");
      let _state = state.configuration.hair;
      let characterCustomization =
        window.parent.canvasTest.characterCustomization;
      characterCustomization.updateHair(_state.optionIndex, _state.colorIndex);
    }
  }, [state.configuration.hair]);

  useEffect(() => {
    if (window.parent.canvasTest) {
      // console.log("face Updated");
      let _state = state.configuration.face;
      let characterCustomization =
        window.parent.canvasTest.characterCustomization;
      characterCustomization.updateSkin(_state.colorIndex);
    }
  }, [state.configuration.face]);

  useEffect(() => {
    if (window.parent.canvasTest) {
      // console.log("tShirt Updated");
      let _state = state.configuration.tShirt;
      let characterCustomization =
        window.parent.canvasTest.characterCustomization;
      characterCustomization.updateCloth(_state.optionIndex, _state.colorIndex);
    }
  }, [state.configuration.tShirt]);

  const lastSpecsVal = useRef([]);
  useEffect(() => {
    if (window.parent.canvasTest) {
      let characterCustomization =
        window.parent.canvasTest.characterCustomization;
      let _state = state.configuration.specs;
      if (!_state.optionIndex) {
        _state.optionIndex = [];
      }
      if (_state.optionIndex.length > lastSpecsVal.current.length) {
        _state.optionIndex.forEach((index) => {
          if (lastSpecsVal.current.indexOf(index) == -1) {
            characterCustomization.updateAccessories(index, true);
          }
        });
      } else {
        lastSpecsVal.current.forEach((index) => {
          if (_state.optionIndex.indexOf(index) == -1) {
            characterCustomization.updateAccessories(index, false);
          }
        });
      }
      lastSpecsVal.current = [...state.configuration.specs.optionIndex];
    }
  }, [state.configuration.specs]);

  // updateHair
  // updateSkin
  // updateCloth
  // updateAccessories
  // updateCharacterConfiguration

  const playBGAudio = () => {
    if (state.isAudioPlaying) {
      if (bgAudioRef.current && bgAudioRef.current.audioEl.current.paused) {
        bgAudioRef.current.audioEl.current.play();
      }
    }
  };
  const stopBGAudio = () => {
    if (bgAudioRef.current && !bgAudioRef.current.audioEl.current.paused) {
      bgAudioRef.current.audioEl.current.pause();
    }
  };

  const handleBGVolumeBtnToggle = () => {
    dispatch({
      type: AVATAR_ACTION_TYPE.toggleBGAudio,
      payload: {},
    });
  };

  return (
    <AvatarContext.Provider
      value={{
        state,
        dispatch,
        switchActiveComponent,
        playBGAudio,
        stopBGAudio,
        bgAudioRef,
        handleBGVolumeBtnToggle,
      }}
    >
      {props.children}
    </AvatarContext.Provider>
  );
}
